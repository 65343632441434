import * as React from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/system/Unstable_Grid';
import CountUp from 'react-countup';
import Typography from '@mui/material/Typography';
import servicesImage from '../images/services-image-01.jpg';
import { grey } from '@mui/material/colors';


export default function SectionCounter() {
    return (
        <Container sx={{ minWidth: '100%', px: '0px !important' }}>
            <Box
                sx={{
                    minwidth: '100%',
                    py: 10,
                    textAlign: 'center',
                    position: 'relative',
                    color: grey[50],
                    '&::before': {
                        display: 'block',
                        content: '""',
                        width: '100%',
                        height: '100%',
                        position: 'absolute',
                        top: '0',
                        left: '0',
                        zIndex: '-1',
                        backgroundImage: `linear-gradient(to right,rgba(8,11,26,0.9),rgba(8,11,26,0.9)), url(${servicesImage})`,
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat'
                    }
                }}
            >
                <Container maxWidth="lg">
                    <Grid container spacing={2} textAlign="center" sx={{ fontWeight: 700, fontSize: 32 }}>
                        <Grid item xs={12} md={4} py={3}>
                            <CountUp end={100} duration={1.5} suffix={' +'} enableScrollSpy={true}/>
                            <Typography fontWeight={500} fontSize={22}>Happy Clients</Typography>
                        </Grid>
                        <Grid item xs={12} md={4} py={3}>
                            <CountUp end={150} duration={1.5} suffix={' +'} enableScrollSpy={true} />
                            <Typography fontWeight={500} fontSize={22}>Projects Done</Typography>
                        </Grid>
                        <Grid item xs={12} md={4} py={3}>
                            <CountUp end={20} duration={1.5} suffix={' +'} enableScrollSpy={true} />
                            <Typography fontWeight={500} fontSize={22}>Professional Courses</Typography>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </Container>
    );
}