import * as React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { grey } from '@mui/material/colors';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import { Link } from '@mui/material';


const ServiceList = () => {
    return (
        <Box bgcolor={grey[100]} p={3} mb={3}>
            <Typography variant="h5" fontWeight={500}>Services</Typography>
            <List>
                <ListItem disablePadding>
                    <Link href="Website-Development" underline="none" color="inherit">
                        <ListItemText sx={{ py: 1.5, transition: '0.35s ease-in-out', '&:hover': { marginLeft: 2 } }} primary="Website Development" />
                    </Link>
                </ListItem>
                <Divider />
                <ListItem disablePadding>
                    <Link href="Search-Engine-Optimization" underline="none" color="inherit">
                        <ListItemText sx={{ py: 1.5, transition: '0.35s ease-in-out', '&:hover': { marginLeft: 2 } }} primary="Search Engine Optimization" />
                    </Link>
                </ListItem>
                <Divider />
                <ListItem disablePadding>
                    <Link href="Digital-Marketing" underline="none" color="inherit">
                        <ListItemText sx={{ py: 1.5, transition: '0.35s ease-in-out', '&:hover': { marginLeft: 2 } }} primary="Digital Marketing" />
                    </Link>
                </ListItem>
                <Divider />
                <ListItem disablePadding>
                    <Link href="Software-Development" underline="none" color="inherit">
                        <ListItemText sx={{ py: 1.5, transition: '0.35s ease-in-out', '&:hover': { marginLeft: 2 } }} primary="Software Development" />
                    </Link>
                </ListItem>
                <Divider />
                <ListItem disablePadding>
                    <Link href="Mobile-App-Development" underline="none" color="inherit">
                        <ListItemText sx={{ py: 1.5, transition: '0.35s ease-in-out', '&:hover': { marginLeft: 2 } }} primary="Mobile App Development" />
                    </Link>
                </ListItem>
                <ListItem disablePadding>
                    <Link href="Professional-IT-Courses" underline="none" color="inherit">
                        <ListItemText sx={{ py: 1.5, transition: '0.35s ease-in-out', '&:hover': { marginLeft: 2 } }} primary="Professional IT Courses" />
                    </Link>
                </ListItem>
            </List>
        </Box>
    );
};
export default ServiceList;