import Box from '@mui/material/Box';
import * as React from "react";
import Slider from "react-slick";
import cgImage from '../images/caribbean-gems-logo.png';
import opmImage from '../images/ozone-pest-management-logo.png';
import tptImage from '../images/time-piece-trading-logo.png';
import servicenowImage from '../images/servicenow-logo.png';
import pkgImage from '../images/pkg-solutions-logo.jpg';
import './SectionOurClients.css';
import { Typography } from '@mui/material';
import { indigo, orange } from '@mui/material/colors';




export default function OurClientsSlider() {
    const settings = {
        autoplay: true,
        dots: false,
        arrows: false,
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        speed: 300,
        responsive: [
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <div className='testimonial'>
            <Typography variant="h2" sx={{ fontSize: 18, textAlign: 'center' }}>OUR CLIENTS</Typography>
            <Box
                sx={{
                    width: '120px',
                    height: '2px',
                    bgcolor: indigo['A400'],
                    position: 'relative',
                    mt: 2,
                    mb: 2,
                    mx: 'auto',
                    '&::before': {
                        display: 'block',
                        content: '""',
                        width: '34px',
                        height: '7px',
                        background: orange['A400'],
                        borderRadius: '30px',
                        position: 'absolute',
                        top: '-2.5px',
                        left: '50%',
                        transform: 'translateX(-50%)'
                    },
                }}>
            </Box>
            <Slider {...settings}>
                <div>
                    <Box sx={{ widht: '100%', bgcolor: '#fff', m: 4, p: 3, position: 'relative', height: 80, display: 'flex', justifyContent: 'center' }}>
                        <img src={cgImage} alt="Caribbean Gems Logo" width="100%" sx={{ display: 'block' }} />
                    </Box>
                </div>
                <div>
                    <Box sx={{ widht: '100%', bgcolor: '#fff', m: 4, p: 3, position: 'relative', height: 80, display: 'flex', justifyContent: 'center' }}>
                        <img src={opmImage} alt="Ozone Pest Management Logo" width="100%" sx={{ position: 'absolute', display: 'block', top: '50%', left: '50%', transform: 'translate(-50%,-50%)', width: 100, height: 100 }} />
                    </Box>
                </div>
                <div>
                    <Box sx={{ widht: '100%', bgcolor: '#fff', m: 4, p: 3, position: 'relative', height: 80, display: 'flex', justifyContent: 'center' }}>
                        <img src={tptImage} alt="Time Piece Trading Logo" height="100%" sx={{ display: 'block' }} />
                    </Box>
                </div>
                <div>
                    <Box sx={{ widht: '100%', bgcolor: '#fff', m: 4, p: 3, position: 'relative', height: 80, display: 'flex', justifyContent: 'center' }}>
                        <img src={pkgImage} alt="PKG Solutions Logo" height="100%" sx={{ display: 'block' }} />
                    </Box>
                </div>
                <div>
                    <Box sx={{ widht: '100%', bgcolor: '#fff', m: 4, p: 3, position: 'relative', height: 80, display: 'flex', justifyContent: 'center' }}>
                        <img src={servicenowImage} alt="Servicenow Logo" width="100%" sx={{ display: 'block' }} />
                    </Box>
                </div>
            </Slider>
        </div>
    );
}