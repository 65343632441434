import './App.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import NavigationMenu from "./Components/Menu";
import Home from "./Home";
import About from "./About";
import Services from "./Services";
import Courses from "./Courses";
import Contact from "./Contact";
import WebsiteDevelopment from './Services/WebsiteDevelopment';
import SearchEngineOptimization from './Services/SearchEngineOptimization';
import DigitalMarketing from './Services/DigitalMarketing';
import SoftwareDevelopment from './Services/SoftwareDevelopment';
import MobileAppDevelopment from './Services/MobileAppDevelopment';
import ProfessionalItCourses from './Services/ProfessionalItCourses';
import Team from './Team';
import SoftwareTesting from './Courses/Software-Testing';
import RahulGajbhiye from './Team/Rahul-Gajbhiye';
import RoshanSahare from './Team/Roshan-Sahare';
import DataAnalyst from './Courses/Data-Analyst';
import FullStackWebDeveloper from './Courses/Full-Stack-Web-Developer';
import SalesForce from './Courses/SalesForce';
import CoreAndAdvancePython from './Courses/CoreAndAdvancePython';
import MeanStackDeveloper from './Courses/MeanStackDeveloper';
import DhaneshwarMondal from './Team/Dhaneshwar-Mondal';
import ShruteshBhowate from './Team/Shrutesh-Bhowate';
import VinayKumar from './Team/Vinay-Kumar';
import Hema from './Team/Hema';
import SandeepTijare from './Team/Sandeep-Tijare';
import TriveshWasnik from './Team/Trivesh-Wasnik';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<NavigationMenu />} >
          <Route index element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="services" element={<Services />} />
          <Route path="courses" element={<Courses />} />
          <Route path="team" element={<Team />} />
          <Route path="contact" element={<Contact />} />
          <Route path="Services/Website-Development" element={<WebsiteDevelopment />} />
          <Route path="Services/Search-Engine-Optimization" element={<SearchEngineOptimization />} />
          <Route path="Services/Digital-Marketing" element={<DigitalMarketing />} />
          <Route path="Services/Software-Development" element={<SoftwareDevelopment />} />
          <Route path="Services/Mobile-App-Development" element={<MobileAppDevelopment />} />
          <Route path="Services/Professional-IT-Courses" element={<ProfessionalItCourses />} />
          <Route path="Courses/Software-Testing" element={<SoftwareTesting />} />
          <Route path="Courses/Data-Analyst" element={<DataAnalyst />} />
          <Route path="Courses/Full-Stack-Web-Developer" element={<FullStackWebDeveloper />} />
          <Route path="Courses/SalesForce" element={<SalesForce />} />
          <Route path="Courses/Core-And-Advance-Python" element={<CoreAndAdvancePython />} />
          <Route path="Courses/Mean-Stack-Developer" element={<MeanStackDeveloper />} />
          <Route path="Team/Rahul-Gajbhiye" element={<RahulGajbhiye />} />
          <Route path="Team/Roshan-Sahare" element={<RoshanSahare />} />
          <Route path="Team/Dhaneshwar-Mondal" element={<DhaneshwarMondal />} />
          <Route path="Team/Shrutesh-Bhowate" element={<ShruteshBhowate />} />
          <Route path="Team/Vinay-Kumar" element={<VinayKumar />} />
          <Route path="Team/Hema" element={<Hema />} />
          <Route path="Team/Sandeep-Tijare" element={<SandeepTijare />} />
          <Route path="Team/Trivesh-Wasnik" element={<TriveshWasnik />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
