import * as React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import { grey } from '@mui/material/colors';
import OpenBook from '../images/open-book.png';
import Analyze from '../images/analyze.png';
import Coding from '../images/coding.png';
import Testing from '../images/testing.png';
import ServiceList from './SectionServiceList';
import CourseList from './SectionCourseList';

const DMPage = () => {
    return (
        <Container sx={{ my: 6 }}>
            <Grid container spacing={2} p={0}>
                <Grid item xs={12} md={8} sx={{ pt: "0 !important" }}>
                    <Box my={5}>
                        <Typography variant="h2" fontSize={28} fontWeight={600} mb={2}>Digital Marketing</Typography>
                        <Typography paragraph={true} sx={{ color: grey[800] }}>Digital marketing is also known as internet marketing. It involves advertising delivered via digital channels to promote brands and connect customers through the internet and other forms of digital communication, such as search engines. Websites. Social media.</Typography>
                    </Box>
                    <Box my={5}>
                        <Typography variant="h3" fontSize={24} fontWeight={500} mb={2}>About this Service</Typography>
                        <Typography paragraph={true} sx={{ color: grey[800] }}>A digital marketing agency is an organization that focuses exclusively on marketing to consumers via digital channels. It includes the creation and launch of campaigns.</Typography>
                        <Typography paragraph={true} sx={{ color: grey[800] }}>Advertising a person, business, product, or service through online channels, electronic devices, and digital technology is marketing and advertising.</Typography>
                    </Box>
                    <Box my={5}>
                        <Typography variant="h3" fontSize={24} fontWeight={500} mb={2}>How we Work</Typography>
                        <Typography paragraph={true} sx={{ color: grey[800] }}>This strategy combines different strategies to reach a single goal. Your goal is to increase traffic to your website. Digital marketing may use various strategies to achieve a goal using different channels, including email, social media, search engine optimization (SEO), pay-per-click, and search engine marketing.</Typography>
                    </Box>
                    <Box my={5}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <Grid container spacing={2}>
                                    <Grid item xs={2}>
                                        <img src={OpenBook} width="100%" alt="Project Study Logo" />
                                    </Grid>
                                    <Grid item xs={10}>
                                        <Typography variant="h6" fontWeight={700} mb={1} fontSize={16}>Research</Typography>
                                        <Typography paragraph={true} sx={{ color: grey[800] }}>We collect all the information about your business, target customers, product to market & online competition that will be required for decision making.</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Grid container spacing={2}>
                                    <Grid item xs={2}>
                                        <img src={Coding} width="100%" alt="Wireframe Logo" />
                                    </Grid>
                                    <Grid item xs={10}>
                                        <Typography variant="h6" fontWeight={700} mb={1} fontSize={16}>Create</Typography>
                                        <Typography paragraph={true} sx={{ color: grey[800] }}>We create digital identities of your business such as Website, Blog & App. Creating digital identities can be optional but it is better to have at least a website for establishing some credibility of your business.</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Grid container spacing={2}>
                                    <Grid item xs={2}>
                                        <img src={Analyze} width="100%" alt="Coding Logo" />
                                    </Grid>
                                    <Grid item xs={10}>
                                        <Typography variant="h6" fontWeight={700} mb={1} fontSize={16}>Promote & Analyze</Typography>
                                        <Typography paragraph={true} sx={{ color: grey[800] }}>We promote your digital identities through various digital marketing channels & start monitoring your performance through Google Analytics.</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Grid container spacing={2}>
                                    <Grid item xs={2}>
                                        <img src={Testing} width="100%" alt="Testing Logo" />
                                    </Grid>
                                    <Grid item xs={10}>
                                        <Typography variant="h6" fontWeight={700} mb={1} fontSize={16}>Optimization</Typography>
                                        <Typography paragraph={true} sx={{ color: grey[800] }}>Based on analysis & observations, we start making changes in your primary digital identities or digital marketing channels.</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                    <ServiceList/>
                    <CourseList/>
                </Grid>
            </Grid>
        </Container>
    )
}

export default DMPage;