import * as React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { grey, indigo } from '@mui/material/colors';
import { orange } from '@mui/material/colors';
import TestimonialSlider from './TestimonialSlider.js';


export default function SectionTestimonials() {
    return (
        <Container sx={{ minWidth: '100%', py: 10, bgcolor: grey[100] }}>
            <Container maxWidht="lg">
                <Box sx={{ py: 3 }}>
                    <Typography variant="h2" sx={{ fontSize: 18, textAlign: 'right' }}>TESTIMONIALS</Typography>
                    <Box
                        sx={{
                            float: 'right',
                            width: '120px',
                            height: '2px',
                            bgcolor: indigo['A400'],
                            position: 'relative',
                            mt: 2,
                            '&::before': {
                                display: 'block',
                                content: '""',
                                width: '34px',
                                height: '7px',
                                background: orange['A400'],
                                borderRadius: '30px',
                                position: 'absolute',
                                top: '-2.5px',
                                right: '0'
                            },
                        }}>
                    </Box>
                    <Typography variant="h3" sx={{ textAlign: 'right', mt: 4, fontSize: 36, fontWeight: '600' }}>Our Clients Loves What We Do</Typography>
                </Box>
            </Container>
            <Container>
                <TestimonialSlider/>
            </Container>
        </Container>
    );
}