import * as React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { grey } from '@mui/material/colors';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { Link } from '@mui/material';
import Divider from '@mui/material/Divider';


const CourseList = () => {
    return (
        <Box py={3}>
            <Typography variant="h5" fontWeight={700} fontSize={18}>COURSES</Typography>
            <Divider sx={{my: 2, width: 30, bgcolor: grey[900], border: 2}}/>
            <List>
                <ListItem disablePadding sx={{border: 1, borderBottom: 0, borderColor: grey[300]}}>
                    <Link href="/Courses/Software-Testing" underline="none" color="inherit">
                        <ListItemText sx={{ px: 3, py: 1.5, transition: '0.35s ease-in-out', '&:hover': { marginLeft: 2 } }} primary="Software Testing" />
                    </Link>
                </ListItem>
                <ListItem disablePadding sx={{border: 1, borderBottom: 0, borderColor: grey[300]}}>
                    <Link href="/Courses/Data-Analyst" underline="none" color="inherit">
                        <ListItemText sx={{ px: 3, py: 1.5, transition: '0.35s ease-in-out', '&:hover': { marginLeft: 2 } }} primary="Data Analyst" />
                    </Link>
                </ListItem>
                <ListItem disablePadding sx={{border: 1, borderBottom: 0, borderColor: grey[300]}}>
                    <Link href="/Courses/Full-Stack-Web-Developer" underline="none" color="inherit">
                        <ListItemText sx={{ px: 3, py: 1.5, transition: '0.35s ease-in-out', '&:hover': { marginLeft: 2 } }} primary="Full Stack Web Developer" />
                    </Link>
                </ListItem>
                <ListItem disablePadding sx={{border: 1, borderBottom: 0, borderColor: grey[300]}}>
                    <Link href="/Courses/SalesForce" underline="none" color="inherit">
                        <ListItemText sx={{ px: 3, py: 1.5, transition: '0.35s ease-in-out', '&:hover': { marginLeft: 2 } }} primary="SalesForce" />
                    </Link>
                </ListItem>
                <ListItem disablePadding sx={{border: 1, borderBottom: 0, borderColor: grey[300]}}>
                    <Link href="/Courses/Core-And-Advance-Python" underline="none" color="inherit">
                        <ListItemText sx={{ px: 3, py: 1.5, transition: '0.35s ease-in-out', '&:hover': { marginLeft: 2 } }} primary="Core & Advance Python" />
                    </Link>
                </ListItem>
                <ListItem disablePadding sx={{border: 1, borderColor: grey[300]}}>
                    <Link href="/Courses/Mean-Stack-Developer" underline="none" color="inherit">
                        <ListItemText sx={{ px: 3, py: 1.5, transition: '0.35s ease-in-out', '&:hover': { marginLeft: 2 } }} primary="MEAN Stack Developer" />
                    </Link>
                </ListItem>
            </List>
        </Box>
    );
};

export default CourseList;
