import styled from 'styled-components';
  
export const Button = styled.div`
   position: fixed; 
   width: 24px;
   height: 24px;
   right: 40px;
   bottom: 40px;
   z-index: 1;
   cursor: pointer;
   color: #ffffff;
`