import React, {useState} from 'react';
import { Button } from './ScrollButtonStyle';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
  
const ScrollButton = () =>{
  
  const [visible, setVisible] = useState(false)
  
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300){
      setVisible(true)
    } 
    else if (scrolled <= 300){
      setVisible(false)
    }
  };
  
  const scrollToTop = () =>{
    window.scrollTo({
      top: 0, 
      behavior: 'smooth'
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };
  
  window.addEventListener('scroll', toggleVisible);
  
  return (
    <Button>
     <ArrowUpwardIcon onClick={scrollToTop} 
     style={{display: visible ? 'inline' : 'none'}} sx={{border: 1, borderColor: 'rgba(8,11,26,0.9)', fontSize: '2rem', bgcolor: 'rgba(8,11,26,0.9)'}} />
    </Button>
  );
}
  
export default ScrollButton;