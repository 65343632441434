import * as React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { grey } from '@mui/material/colors';
import { indigo } from '@mui/material/colors';
import { orange } from '@mui/material/colors';
import Grid from '@mui/system/Unstable_Grid';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { Link } from "react-router-dom";

const ButtonBox = styled('div')(({ theme }) => ({
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    height: '100%',
    justifyContent: 'right',
    [theme.breakpoints.down('md')]: {
        justifyContent: 'left',
        height: 'auto'
    },

}));

export default function SectionContact() {
    return (
        <Container sx={{ minWidth: '100%', py: 6, bgcolor: grey[50] }}>
            <Container maxWidht="lg">
                <Box sx={{ py: 3 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Typography variant="h3" sx={{ mt: 4, fontSize: 36, fontWeight: '600' }}>Get Started Today</Typography>
                            <Box
                                sx={{
                                    width: '120px',
                                    height: '2px',
                                    bgcolor: indigo['A400'],
                                    position: 'relative',
                                    mt: 2,
                                    '&::before': {
                                        display: 'block',
                                        content: '""',
                                        width: '34px',
                                        height: '7px',
                                        background: orange['A400'],
                                        borderRadius: '30px',
                                        position: 'absolute',
                                        top: '-2.5px'
                                    },
                                }}>
                            </Box>

                            <Typography paragraph={true} sx={{ mt: 4 }}>
                                <Box component="span" sx={{ fontWeight: 'bold' }}>Qbitt Technologies</Box> always try to provide the best IT Solutions for Clients to grow up their Business very sharply and smoothly.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <ButtonBox>
                                <Link to="/Contact" style={{ textDecoration: 'none' }}>
                                    <Button variant="outlined" sx={{ borderRadius: 30, color: '#000', border: '2px solid #000', fontSize: 16, fontWeight: 600, px: 6, py: 1.5, '&:hover': { border: '2px solid rgba(8,11,26,.9)', bgcolor: 'rgb(8,11,26)', color: '#fff' } }}>Contact Us</Button>
                                </Link>
                            </ButtonBox>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </Container >
    );
}