import * as React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/system/Unstable_Grid';
import { grey, indigo } from '@mui/material/colors';
import { orange } from '@mui/material/colors';
import { Link } from '@mui/material';
import servicesImage from '../images/services-bg.jpg';
import wdImage from '../images/website-development.jpg';
import seoImage from '../images/search-engine-optimization.jpg';
import dmImage from '../images/digital-marketing.jpg';
import sdImage from '../images/software-development.jpg';
import aadImage from '../images/android-app-development.jpg';
import pitcImage from '../images/professional-it-courses.jpg';

export default function SectionOurServices() {
    return (
        <Container maxWidth="xl" sx={{ minWidth: '100%', px: '0px !important', pb: 6 }}>
            <Box
                sx={{
                    minwidth: '100%',
                    py: 10,
                    textAlign: 'center',
                    position: 'relative',
                    color: grey[50],
                    '&::before': {
                        display: 'block',
                        content: '""',
                        width: '100%',
                        height: '100%',
                        position: 'absolute',
                        top: '0',
                        left: '0',
                        zIndex: '-1',
                        backgroundImage: `linear-gradient(to right,rgba(8,11,26,0.9),rgba(8,11,26,0.9)), url(${servicesImage})`,
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat'
                    }
                }}
            >
                <Typography variant="h2" sx={{ fontSize: 18 }}>OUR SERVICES</Typography>
                <Box
                    sx={{
                        width: '120px',
                        height: '2px',
                        bgcolor: indigo['A400'],
                        position: 'relative',
                        mt: 2,
                        mx: 'auto',
                        '&::before': {
                            display: 'block',
                            content: '""',
                            width: '34px',
                            height: '7px',
                            background: orange['A400'],
                            borderRadius: '30px',
                            position: 'absolute',
                            top: '-2.5px',
                            left: '50%',
                            transform: 'translateX(-50%)'
                        },
                    }}>
                </Box>
                <Typography paragraph={true} sx={{ mt: 5, fontSize: '2rem', fontWeight: '600' }}>
                    We make it simple, providing best solutions
                </Typography>
            </Box>
            <Container maxWidth="lg">
                <Box sx={{ flexGrow: 1, mt: -8 }}>
                    <Grid container spacing={4}>
                        <Grid xs={12} md={4}>
                            <Link href="Services/Website-Development" underline="none" color="inherit">
                                <Box sx={{ bgcolor: indigo['50'], position: 'relative', overflow: 'hidden', textAlign: 'center', '&:hover': { '& .overlap': { right: '0' } } }} >
                                    <Box sx={{ width: '100%', height: '100%', color: grey[100], bgcolor: 'rgba(8,11,26,0.9)', position: 'absolute', right: '100%', zIndex: 1, transition: '.3s ease-in-out' }} className="overlap" >
                                        <Typography variant="h3" sx={{ py: 2, fontSize: 20, fontWeight: 600, px: 3, mt: 7 }}>Website Development</Typography>
                                        <Typography paragraph='true' px={3} >India's best web development company offers professional website design services for business growth and user-friendly and conversion-driven websites.</Typography>
                                    </Box>
                                    <img src={wdImage} alt="Qbitt Technologies Services" width="100%" />
                                    <Typography variant="h3" sx={{ py: 2, fontSize: 22, fontWeight: 600 }}>Website Development</Typography>
                                </Box>
                            </Link>
                        </Grid>
                        <Grid xs={12} md={4}>
                            <Link href="Services/Search-Engine-Optimization" underline="none" color="inherit">
                            <Box sx={{ bgcolor: indigo['50'], position: 'relative', overflow: 'hidden', textAlign: 'center', '&:hover': { '& .overlap': { right: '0' } } }} >
                                    <Box sx={{ width: '100%', height: '100%', color: grey[100], bgcolor: 'rgba(8,11,26,0.9)', position: 'absolute', right: '100%', zIndex: 1, transition: '.3s ease-in-out' }} className="overlap" >
                                        <Typography variant="h3" sx={{ py: 2, fontSize: 20, fontWeight: 600, px: 3, mt: 7  }}>Search Engine Optimization</Typography>
                                        <Typography paragraph='true' px={3} >SEO stands for search engine optimization. It is the process of optimizing your website to make it more visible when people search for your requirements on Google.</Typography>
                                    </Box>
                                    <img src={seoImage} alt="Qbitt Technologies Services" width="100%" />
                                    <Typography variant="h3" sx={{ py: 2, fontSize: 22, fontWeight: 600 }}>Search Engine Optimization</Typography>
                                </Box>
                            </Link>
                        </Grid>
                        <Grid xs={12} md={4}>
                            <Link href="Services/Digital-Marketing" underline="none" color="inherit">
                            <Box sx={{ bgcolor: indigo['50'], position: 'relative', overflow: 'hidden', textAlign: 'center', '&:hover': { '& .overlap': { right: '0' } } }} >
                                    <Box sx={{ width: '100%', height: '100%', color: grey[100], bgcolor: 'rgba(8,11,26,0.9)', position: 'absolute', right: '100%', zIndex: 1, transition: '.3s ease-in-out' }} className="overlap" >
                                        <Typography variant="h3" sx={{ py: 2, fontSize: 20, fontWeight: 600, px: 3, mt: 7  }}>Digital Marketing</Typography>
                                        <Typography paragraph='true' px={3} >Digital marketing is also known as internet marketing. It involves advertising delivered via digital channels to promote brands and connect customers through the internet and other forms of digital communication.</Typography>
                                    </Box>
                                    <img src={dmImage} alt="Qbitt Technologies Services" width="100%" />
                                    <Typography variant="h3" sx={{ py: 2, fontSize: 22, fontWeight: 600 }}>Digital Marketing</Typography>
                                </Box>
                            </Link>
                        </Grid>
                        <Grid xs={12} md={4}>
                            <Link href="Services/Software-Development" underline="none" color="inherit">
                            <Box sx={{ bgcolor: indigo['50'], position: 'relative', overflow: 'hidden', textAlign: 'center', '&:hover': { '& .overlap': { right: '0' } } }} >
                                    <Box sx={{ width: '100%', height: '100%', color: grey[100], bgcolor: 'rgba(8,11,26,0.9)', position: 'absolute', right: '100%', zIndex: 1, transition: '.3s ease-in-out' }} className="overlap" >
                                        <Typography variant="h3" sx={{ py: 2, fontSize: 20, fontWeight: 600, px: 3, mt: 7  }}>Software Development</Typography>
                                        <Typography paragraph='true' px={3} >Software development is the process of creating software. It includes design, documentation, programming, and testing. These steps are combined to create a workflow pipeline.</Typography>
                                    </Box>
                                    <img src={sdImage} alt="Qbitt Technologies Services" width="100%" />
                                    <Typography variant="h3" sx={{ py: 2, fontSize: 22, fontWeight: 600 }}>Software Development</Typography>
                                </Box>
                            </Link>
                        </Grid>
                        <Grid xs={12} md={4}>
                            <Link href="Services/Mobile-App-Development" underline="none" color="inherit">
                            <Box sx={{ bgcolor: indigo['50'], position: 'relative', overflow: 'hidden', textAlign: 'center', '&:hover': { '& .overlap': { right: '0' } } }} >
                                    <Box sx={{ width: '100%', height: '100%', color: grey[100], bgcolor: 'rgba(8,11,26,0.9)', position: 'absolute', right: '100%', zIndex: 1, transition: '.3s ease-in-out' }} className="overlap" >
                                        <Typography variant="h3" sx={{ py: 2, fontSize: 20, fontWeight: 600, px: 3, mt: 7  }}>Mobile App Development</Typography>
                                        <Typography paragraph='true' px={3} >Application Development is a collection of computer applications on mobile devices like tablets, smartphones, and smartwatches.</Typography>
                                    </Box>
                                    <img src={aadImage} alt="Qbitt Technologies Services" width="100%" />
                                    <Typography variant="h3" sx={{ py: 2, fontSize: 22, fontWeight: 600 }}>Mobile App Development</Typography>
                                </Box>
                            </Link>
                        </Grid>
                        <Grid xs={12} md={4}>
                            <Link href="Services/Professional-IT-Courses" underline="none" color="inherit">
                            <Box sx={{ bgcolor: indigo['50'], position: 'relative', overflow: 'hidden', textAlign: 'center', '&:hover': { '& .overlap': { right: '0' } } }} >
                                    <Box sx={{ width: '100%', height: '100%', color: grey[100], bgcolor: 'rgba(8,11,26,0.9)', position: 'absolute', right: '100%', zIndex: 1, transition: '.3s ease-in-out' }} className="overlap" >
                                        <Typography variant="h3" sx={{ py: 2, fontSize: 20, fontWeight: 600, px: 3, mt: 7 }}>Professional IT Courses</Typography>
                                        <Typography paragraph='true' px={3} >Learn with us to become a qualified IT professional,  We provide a variety of IT courses, and experts provide support and tutoring for immersive learning.</Typography>
                                    </Box>
                                    <img src={pitcImage} alt="Qbitt Technologies Services" width="100%" />
                                    <Typography variant="h3" sx={{ py: 2, fontSize: 22, fontWeight: 600 }}>Professional IT Courses</Typography>
                                </Box>
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </Container>
    );
}
