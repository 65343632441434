import * as React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { indigo, orange } from '@mui/material/colors';
import Grid from '@mui/system/Unstable_Grid';
import teamImage from '../images/Team.jpg';

export default function SectionOurTeamTwo() {
    return (
        <Container sx={{ minWidth: '100%', my: 6 }}>

            <Container maxWidht="lg">
                <Box sx={{ py: 3 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Typography variant="h2" sx={{ fontSize: 18 }}>Our Team</Typography>
                            <Box
                                sx={{
                                    width: '120px',
                                    height: '2px',
                                    bgcolor: indigo['A400'],
                                    position: 'relative',
                                    mt: 2,
                                    '&::before': {
                                        display: 'block',
                                        content: '""',
                                        width: '34px',
                                        height: '7px',
                                        background: orange['A400'],
                                        borderRadius: '30px',
                                        position: 'absolute',
                                        top: '-2.5px'
                                    },
                                }}>
                            </Box>
                            <Typography variant="h3" sx={{ mt: 4, fontSize: 36, fontWeight: '600' }}>Meet Our Expert Members</Typography>
                            <Typography paragraph={true} sx={{ mt: 4 }}>
                                The <Box component="span" sx={{ fontWeight: 'bold' }}>Qbitt Technologies</Box> team is comprised of industry experts who are passionate about their work, have excelled in their field, and have an extensive background in a wide variety of applications. Our team delivers a full-range of skill-sets to meet your needs up and down the stack, including strategic planners, project management professionals (PMP), and tactical specialists.
                            </Typography>
                            <Typography paragraph={true} sx={{ mt: 4 }}>Their IT intelligence supports midsize to large enterprise businesses in India and across the globe. This group of talented professionals has been responsible for the satisfaction, retention and growth of our base of clients since 2016.</Typography>
                        </Grid>
                        <Grid item xs={12} md={6} sx={{ position: 'relative', px: 5 }}>
                            <img src={teamImage} alt="About Qbitt Technology" width="100%" />
                        </Grid>
                    </Grid>
                </Box>

            </Container>
        </Container>

    );
};