import { Outlet, Link } from "react-router-dom";
import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import FacebookIcon from '@mui/icons-material/Facebook';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { createTheme } from '@mui/material/styles';
import logo from '../logo.png';
const theme = createTheme({
    palette: {
        primary: {
            light: '#757ce8',
            main: 'rgb(8,11,26)',
            dark: '#002884',
            contrastText: '#fff',
        },
        secondary: {
            light: '#ff7961',
            main: '#f44336',
            dark: '#ba000d',
            contrastText: '#000'
        },
    },
});





const NavigationMenu = () => {
    const pages = ['About', 'Services', 'Courses', 'Team', 'Contact'];


    const [anchorElNav, setAnchorElNav] = React.useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    return (
        <>
            <AppBar position="sticky" theme={theme}>
                <Container maxWidth="xl" sx={{ py: '1rem !important' }}>
                    <Toolbar>
                        <Typography
                            variant="h6"
                            noWrap
                            component="a"
                            href="/"
                            sx={{
                                mr: 2,
                                display: { xs: 'none', md: 'flex' },
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                letterSpacing: '.1rem',
                                color: 'inherit',
                                textDecoration: 'none',
                            }}
                        >
                            <img src={logo} width="140px" alt="Qbitt Technologies Logo" />
                        </Typography>

                        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="inherit"
                            >
                                <MenuIcon />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{
                                    display: { xs: 'block', md: 'none' },
                                }}
                            >
                                <Link to="/" style={{ textDecoration: 'none', color: 'rgb(8,11,26)'}}>
                                    <MenuItem onClick={handleCloseNavMenu}>
                                        <Typography textAlign="center" letterSpacing={2}>Home</Typography>
                                    </MenuItem>
                                </Link>
                                {pages.map((page) => (
                                    <Link to={page} style={{ textDecoration: 'none', color: 'rgb(8,11,26)' }}>
                                        <MenuItem key={page} onClick={handleCloseNavMenu}>
                                            <Typography textAlign="center" letterSpacing={2}>{page}</Typography>
                                        </MenuItem>
                                    </Link>
                                ))}
                            </Menu>
                        </Box>


                        <Typography
                            variant="h5"
                            noWrap
                            component="a"
                            href="/"
                            sx={{
                                mr: 2,
                                display: { xs: 'flex', md: 'none' },
                                flexGrow: 1,
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                letterSpacing: '.1rem',
                                color: 'inherit',
                                textDecoration: 'none',
                            }}
                        >
                            <img src={logo} width="130px" alt="Qbitt Technologies Logo" />
                        </Typography>
                        <Box sx={{ mx: 10, flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                            <Link to="/" style={{ textDecoration: 'none' }}>
                                <Button
                                    onClick={handleCloseNavMenu}
                                    sx={{ my: 2, color: 'white', display: 'block', letterSpacing: 2, fontSize: 16 }}
                                >Home
                                </Button>
                            </Link>

                            {pages.map((page) => (
                                <Link style={{ textDecoration: 'none' }}
                                    to={page}
                                >
                                    <Button
                                        key={page}
                                        onClick={handleCloseNavMenu}
                                        sx={{ my: 2, color: 'white !important', display: 'block', letterSpacing: 2, fontSize: 16 }}
                                    >
                                        {page}
                                    </Button>
                                </Link>
                            ))}
                        </Box>
                        <Box sx={{ display: { xs: 'flex', md: 'flex' } }}>
                            <IconButton
                                size='large'
                                color="inherit"
                                href="https://www.facebook.com/people/Qbitt-Technologies/100078775545401/"
                            >
                                <FacebookIcon />
                            </IconButton>
                            <IconButton
                                size='large'
                                color="inherit"
                                href="https://wa.me/917020171320"
                            >
                                <WhatsAppIcon />
                            </IconButton>
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
            <Outlet />
        </>
    )
};

export default NavigationMenu;