import * as React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import { grey } from '@mui/material/colors';
import OpenBook from '../images/open-book.png';
import Wireframe from '../images/wireframe.png';
import Coding from '../images/coding.png';
import Testing from '../images/testing.png';
import ServiceList from './SectionServiceList';
import CourseList from './SectionCourseList';

const AADPage = () => {
    return (
        <Container sx={{ my: 6 }}>
            <Grid container spacing={2} p={0}>
                <Grid item xs={12} md={8} sx={{ pt: "0 !important" }}>
                    <Box my={5}>
                        <Typography variant="h2" fontSize={28} fontWeight={600} mb={2}>Mobile App Development</Typography>
                        <Typography paragraph={true} sx={{ color: grey[800] }}>Application Development is a collection of computer applications on mobile devices like tablets, smartphones, and smartwatches.</Typography>
                    </Box>
                    <Box my={5}>
                        <Typography variant="h3" fontSize={24} fontWeight={500} mb={2}>About this Service</Typography>
                        <Typography paragraph={true} sx={{ color: grey[800] }}>Mobile application development refers to creating software applications that can run on a smartphone. This is a typical mobile app used by customers in their day-to-day lives for their service needs.</Typography>
                        <Typography paragraph={true} sx={{ color: grey[800] }}> We, Qbitt Technologies, are one of the renowned mobile app development company which realize your unbaked ideas into a robust and scalable mobile app. We offer this service for all different platforms which includes android, iOS, Windows, Beacon and for cross platform also. Not just development, but we offer an end-to-end solution to build unique and extensible mobile apps, with proper technical support and maintenance services afterward.</Typography>
                    </Box>
                    <Box my={5}>
                        <Typography variant="h3" fontSize={24} fontWeight={500} mb={2}>How we Work</Typography>
                        <Typography paragraph={true} sx={{ color: grey[800] }}>App developers usually work in Coding, designing, and application management. They also provide end-user support.</Typography>
                    </Box>
                    <Box my={5}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <Grid container spacing={2}>
                                    <Grid item xs={2}>
                                        <img src={OpenBook} width="100%" alt="Project Study Logo" />
                                    </Grid>
                                    <Grid item xs={10}>
                                        <Typography variant="h6" fontWeight={700} mb={1} fontSize={16}>Pre-design</Typography>
                                        <Typography paragraph={true} sx={{ color: grey[800] }}>Pre-design is the first step in the app development process. It involves taking a project's initial brief (either one line or thirty pages) and turning it into a viable strategy.</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Grid container spacing={2}>
                                    <Grid item xs={2}>
                                        <img src={Wireframe} width="100%" alt="Wireframe Logo" />
                                    </Grid>
                                    <Grid item xs={10}>
                                        <Typography variant="h6" fontWeight={700} mb={1} fontSize={16}>Design</Typography>
                                        <Typography paragraph={true} sx={{ color: grey[800] }}>After you've completed the pre-design phase and received the approach's unqualified recommendation, it's time to start designing.</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Grid container spacing={2}>
                                    <Grid item xs={2}>
                                        <img src={Coding} width="100%" alt="Coding Logo" />
                                    </Grid>
                                    <Grid item xs={10}>
                                        <Typography variant="h6" fontWeight={700} mb={1} fontSize={16}>Development</Typography>
                                        <Typography paragraph={true} sx={{ color: grey[800] }}>Now it's time for the construction.</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Grid container spacing={2}>
                                    <Grid item xs={2}>
                                        <img src={Testing} width="100%" alt="Testing Logo" />
                                    </Grid>
                                    <Grid item xs={10}>
                                        <Typography variant="h6" fontWeight={700} mb={1} fontSize={16}>Support</Typography>
                                        <Typography paragraph={true} sx={{ color: grey[800] }}>Now, you should have a live app. Let your audience know that you are using it and provide support.</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                    <ServiceList/>
                    <CourseList/>
                </Grid>
            </Grid>
        </Container>
    )
}

export default AADPage;