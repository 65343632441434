import * as React from "react";
import Slider from "react-slick";
import Box from '@mui/material/Box';
import { grey, indigo, orange } from '@mui/material/colors';
import Container from '@mui/material/Container';
import BannerImageOne from '../images/banner-01.jpg';
import BannerImageTwo from '../images/banner-02.jpg';
import BannerImageThree from '../images/banner-03.jpg';
import BannerImageFour from '../images/banner-04.jpg';
import BannerImageFive from '../images/banner-05.jpg';
import BannerImageSix from '../images/banner-06.jpg';
import BannerImageSeven from '../images/banner-07.jpg';
import './BannerSlider.css';
import { Typography } from "@mui/material";
import Grid from '@mui/system/Unstable_Grid';
import Button from '@mui/material/Button';
import { Link } from "react-router-dom";



export default function TestimonialSlider() {
    const settings = {
        autoplay: true,
        dots: true,
        arrows: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 500,
    };
    return (
        <div>
            <Slider {...settings} className="banner-slider">
                <div>
                    <Box
                        sx={{
                            minwidth: '100%',
                            height: '90vh',
                            py: 10,
                            color: grey[50],
                            position: 'relative',
                            '&::before': {
                                display: 'block',
                                content: '""',
                                width: '100%',
                                height: '100%',
                                position: 'absolute',
                                top: '0',
                                left: '0',
                                zIndex: '-1',
                                backgroundImage: `linear-gradient(to right,rgba(8,11,26,0.5),rgba(8,11,26,0.5)), url(${BannerImageOne})`,
                                backgroundPosition: 'center top',
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat'
                            }
                        }}
                    >
                        <Container maxWidth="lg">
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <Typography variant="h1" fontWeight={600} fontSize={50}>Grow Your Business Digitally</Typography>
                                    <Box
                                        sx={{
                                            width: '120px',
                                            height: '2px',
                                            bgcolor: indigo['A400'],
                                            position: 'relative',
                                            mt: 2,
                                            '&::before': {
                                                display: 'block',
                                                content: '""',
                                                width: '34px',
                                                height: '7px',
                                                background: orange['A400'],
                                                borderRadius: '30px',
                                                position: 'absolute',
                                                top: '-2.5px'
                                            },
                                        }}>
                                    </Box>
                                    <Typography fontWeight={400} fontSize={14} paragraph={true} mt={4}>Take your business to the Next Level. Establish Brand Consistency and Identity. Make your Online Business Presence with <Box component="span" sx={{ fontWeight: 600 }}>Qbitt Technologies</Box>. We are Happy to Help you Build a Profitable Online Business.</Typography>
                                    <Link to="/Services" style={{ textDecoration: 'none' }}>
                                        <Button variant="outlined" sx={{ borderRadius: 30, color: grey[50], border: `2px solid ${grey[50]}`, fontSize: 16, fontWeight: 600, px: 6, py: 1.5, mt: 4, '&:hover': { border: '2px solid rgba(8,11,26,.9)', bgcolor: 'rgb(8,11,26)', color: '#fff' } }}>Learn More</Button>
                                    </Link>
                                </Grid>
                            </Grid>
                        </Container>
                    </Box>
                </div>
                <div>
                    <Box
                        sx={{
                            minwidth: '100%',
                            height: '90vh',
                            py: 10,
                            color: grey[50],
                            position: 'relative',
                            '&::before': {
                                display: 'block',
                                content: '""',
                                width: '100%',
                                height: '100%',
                                position: 'absolute',
                                top: '0',
                                left: '0',
                                zIndex: '-1',
                                backgroundImage: `linear-gradient(to right,rgba(8,11,26,0.5),rgba(8,11,26,0.5)), url(${BannerImageTwo})`,
                                backgroundPosition: 'center top',
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat'
                            }
                        }}
                    >
                        <Container maxWidth="lg">
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <Typography variant="h1" fontWeight={600} fontSize={40}>IT Support & Assistance For Your Business</Typography>
                                    <Box
                                        sx={{
                                            width: '120px',
                                            height: '2px',
                                            bgcolor: indigo['A400'],
                                            position: 'relative',
                                            mt: 2,
                                            '&::before': {
                                                display: 'block',
                                                content: '""',
                                                width: '34px',
                                                height: '7px',
                                                background: orange['A400'],
                                                borderRadius: '30px',
                                                position: 'absolute',
                                                top: '-2.5px'
                                            },
                                        }}>
                                    </Box>
                                    <Typography fontWeight={400} fontSize={14} paragraph={true} mt={4}><Box component="span" sx={{ fontWeight: 600 }}>Qbitt Technologies</Box> can help you establish a strong online presence. We make your Brand consistent and identity to take you to the top. So definitely, your business can reach the next level with us.</Typography>
                                    <Link to="/Services" style={{ textDecoration: 'none' }}>
                                        <Button variant="outlined" sx={{ borderRadius: 30, color: grey[50], border: `2px solid ${grey[50]}`, fontSize: 16, fontWeight: 600, px: 6, py: 1.5, mt: 4, '&:hover': { border: '2px solid rgba(8,11,26,.9)', bgcolor: 'rgb(8,11,26)', color: '#fff' } }}>Learn More</Button>
                                    </Link>
                                </Grid>
                            </Grid>
                        </Container>
                    </Box>
                </div>
                <div>
                    <Box
                        sx={{
                            minwidth: '100%',
                            height: '90vh',
                            py: 10,
                            color: grey[50],
                            position: 'relative',
                            '&::before': {
                                display: 'block',
                                content: '""',
                                width: '100%',
                                height: '100%',
                                position: 'absolute',
                                top: '0',
                                left: '0',
                                zIndex: '-1',
                                backgroundImage: `linear-gradient(to right,rgba(8,11,26,0.5),rgba(8,11,26,0.5)), url(${BannerImageThree})`,
                                backgroundPosition: 'center top',
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat'
                            }
                        }}
                    >
                        <Container maxWidth="lg">
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <Typography variant="h1" fontWeight={600} fontSize={40}>Job Oriented Professional IT Courses</Typography>
                                    <Box
                                        sx={{
                                            width: '120px',
                                            height: '2px',
                                            bgcolor: indigo['A400'],
                                            position: 'relative',
                                            mt: 2,
                                            '&::before': {
                                                display: 'block',
                                                content: '""',
                                                width: '34px',
                                                height: '7px',
                                                background: orange['A400'],
                                                borderRadius: '30px',
                                                position: 'absolute',
                                                top: '-2.5px'
                                            },
                                        }}>
                                    </Box>
                                    <Typography fontWeight={400} fontSize={14} paragraph={true} mt={4}><Box component="span" sx={{ fontWeight: 600 }}>Qbitt Technologies</Box> offer professional training courses and certifications. You will learn basic to advanced software development skills from highly-skilled, experienced industrial trainers. Master Full-Stack and Backend skills in an environment conducive to obtaining interviews.</Typography>
                                    <Link to="/Courses" style={{ textDecoration: 'none' }}>
                                        <Button variant="outlined" sx={{ borderRadius: 30, color: grey[50], border: `2px solid ${grey[50]}`, fontSize: 16, fontWeight: 600, px: 6, py: 1.5, mt: 4, '&:hover': { border: '2px solid rgba(8,11,26,.9)', bgcolor: 'rgb(8,11,26)', color: '#fff' } }}>Learn More</Button>
                                    </Link>
                                </Grid>
                            </Grid>
                        </Container>
                    </Box>
                </div>
                <div>
                    <Box
                        sx={{
                            minwidth: '100%',
                            height: '90vh',
                            py: 10,
                            color: grey[50],
                            position: 'relative',
                            '&::before': {
                                display: 'block',
                                content: '""',
                                width: '100%',
                                height: '100%',
                                position: 'absolute',
                                top: '0',
                                left: '0',
                                zIndex: '-1',
                                backgroundImage: `linear-gradient(to right,rgba(8,11,26,0.5),rgba(8,11,26,0.5)), url(${BannerImageFour})`,
                                backgroundPosition: 'center top',
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat'
                            }
                        }}
                    >
                        <Container maxWidth="lg">
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <Typography variant="h1" fontWeight={600} fontSize={40}>Advanced Cloud Computing Services</Typography>
                                    <Box
                                        sx={{
                                            width: '120px',
                                            height: '2px',
                                            bgcolor: indigo['A400'],
                                            position: 'relative',
                                            mt: 2,
                                            '&::before': {
                                                display: 'block',
                                                content: '""',
                                                width: '34px',
                                                height: '7px',
                                                background: orange['A400'],
                                                borderRadius: '30px',
                                                position: 'absolute',
                                                top: '-2.5px'
                                            },
                                        }}>
                                    </Box>
                                    <Typography fontWeight={400} fontSize={14} paragraph={true} mt={4}> The cloud has revolutionized the way businesses operate and grow. <Box component="span" sx={{ fontWeight: 600 }}>Qbitt Technologies</Box> delivers flexible, future-proof IT infrastructure to companies ready to meet the revolution head on. Choose a best-fit cloud solution customized for the business you have today, and ready to scale when you are.</Typography>
                                    <Link to="/Services" style={{ textDecoration: 'none' }}>
                                        <Button variant="outlined" sx={{ borderRadius: 30, color: grey[50], border: `2px solid ${grey[50]}`, fontSize: 16, fontWeight: 600, px: 6, py: 1.5, mt: 4, '&:hover': { border: '2px solid rgba(8,11,26,.9)', bgcolor: 'rgb(8,11,26)', color: '#fff' } }}>Learn More</Button>
                                    </Link>
                                </Grid>
                            </Grid>
                        </Container>
                    </Box>
                </div>
                <div>
                    <Box
                        sx={{
                            minwidth: '100%',
                            height: '90vh',
                            py: 10,
                            color: grey[50],
                            position: 'relative',
                            '&::before': {
                                display: 'block',
                                content: '""',
                                width: '100%',
                                height: '100%',
                                position: 'absolute',
                                top: '0',
                                left: '0',
                                zIndex: '-1',
                                backgroundImage: `linear-gradient(to right,rgba(8,11,26,0.5),rgba(8,11,26,0.5)), url(${BannerImageFive})`,
                                backgroundPosition: 'center top',
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat'
                            }
                        }}
                    >
                        <Container maxWidth="lg">
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <Typography variant="h1" fontWeight={600} fontSize={38}>Blockchain Development</Typography>
                                    <Box
                                        sx={{
                                            width: '120px',
                                            height: '2px',
                                            bgcolor: indigo['A400'],
                                            position: 'relative',
                                            mt: 2,
                                            '&::before': {
                                                display: 'block',
                                                content: '""',
                                                width: '34px',
                                                height: '7px',
                                                background: orange['A400'],
                                                borderRadius: '30px',
                                                position: 'absolute',
                                                top: '-2.5px'
                                            },
                                        }}>
                                    </Box>
                                    <Typography fontWeight={400} fontSize={14} paragraph={true} mt={4}><Box component="span" sx={{ fontWeight: 600 }}>Qbitt Technologies</Box> implements Smart Contracts, Decentralized Applications and Web3 Products using Blockchain to deliver trust, traceability and transparency.</Typography>
                                    <Link to="/Services" style={{ textDecoration: 'none' }}>
                                        <Button variant="outlined" sx={{ borderRadius: 30, color: grey[50], border: `2px solid ${grey[50]}`, fontSize: 16, fontWeight: 600, px: 6, py: 1.5, mt: 4, '&:hover': { border: '2px solid rgba(8,11,26,.9)', bgcolor: 'rgb(8,11,26)', color: '#fff' } }}>Learn More</Button>
                                    </Link>
                                </Grid>
                            </Grid>
                        </Container>
                    </Box>
                </div>
                <div>
                    <Box
                        sx={{
                            minwidth: '100%',
                            height: '90vh',
                            py: 10,
                            color: grey[50],
                            position: 'relative',
                            '&::before': {
                                display: 'block',
                                content: '""',
                                width: '100%',
                                height: '100%',
                                position: 'absolute',
                                top: '0',
                                left: '0',
                                zIndex: '-1',
                                backgroundImage: `linear-gradient(to right,rgba(8,11,26,0.5),rgba(8,11,26,0.5)), url(${BannerImageSix})`,
                                backgroundPosition: 'center top',
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat'
                            }
                        }}
                    >
                        <Container maxWidth="lg">
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <Typography variant="h1" fontWeight={600} fontSize={38}>Project Management</Typography>
                                    <Box
                                        sx={{
                                            width: '120px',
                                            height: '2px',
                                            bgcolor: indigo['A400'],
                                            position: 'relative',
                                            mt: 2,
                                            '&::before': {
                                                display: 'block',
                                                content: '""',
                                                width: '34px',
                                                height: '7px',
                                                background: orange['A400'],
                                                borderRadius: '30px',
                                                position: 'absolute',
                                                top: '-2.5px'
                                            },
                                        }}>
                                    </Box>
                                    <Typography fontWeight={400} fontSize={14} paragraph={true} mt={4}>At <Box component="span" sx={{ fontWeight: 600 }}>Qbitt Technologies</Box>, we’ve designed our teams, capabilities, and processes around three fundamental principles: Strategy, Design, and Technology. Together, these principles enable us to offer breadth and depth: Solutions that align to clients’ objectives, provide the best experience for end users, and deliver results.</Typography>
                                    <Link to="/Services" style={{ textDecoration: 'none' }}>
                                        <Button variant="outlined" sx={{ borderRadius: 30, color: grey[50], border: `2px solid ${grey[50]}`, fontSize: 16, fontWeight: 600, px: 6, py: 1.5, mt: 4, '&:hover': { border: '2px solid rgba(8,11,26,.9)', bgcolor: 'rgb(8,11,26)', color: '#fff' } }}>Learn More</Button>
                                    </Link>
                                </Grid>
                            </Grid>
                        </Container>
                    </Box>
                </div>
                <div>
                    <Box
                        sx={{
                            minwidth: '100%',
                            height: '90vh',
                            py: 10,
                            color: grey[50],
                            position: 'relative',
                            '&::before': {
                                display: 'block',
                                content: '""',
                                width: '100%',
                                height: '100%',
                                position: 'absolute',
                                top: '0',
                                left: '0',
                                zIndex: '-1',
                                backgroundImage: `linear-gradient(to right,rgba(8,11,26,0.5),rgba(8,11,26,0.5)), url(${BannerImageSeven})`,
                                backgroundPosition: 'center top',
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat'
                            }
                        }}
                    >
                        <Container maxWidth="lg">
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <Typography variant="h1" fontWeight={600} fontSize={38}>Mobile Device Management</Typography>
                                    <Box
                                        sx={{
                                            width: '120px',
                                            height: '2px',
                                            bgcolor: indigo['A400'],
                                            position: 'relative',
                                            mt: 2,
                                            '&::before': {
                                                display: 'block',
                                                content: '""',
                                                width: '34px',
                                                height: '7px',
                                                background: orange['A400'],
                                                borderRadius: '30px',
                                                position: 'absolute',
                                                top: '-2.5px'
                                            },
                                        }}>
                                    </Box>
                                    <Typography fontWeight={400} fontSize={14} paragraph={true} mt={4}>
                                        Start widening the perimeters of business computing with <Box component="span" sx={{ fontWeight: 600 }}>Qbitt Technologies</Box> Mobile Device Management system.</Typography>
                                    <Link to="/Services" style={{ textDecoration: 'none' }}>
                                        <Button variant="outlined" sx={{ borderRadius: 30, color: grey[50], border: `2px solid ${grey[50]}`, fontSize: 16, fontWeight: 600, px: 6, py: 1.5, mt: 4, '&:hover': { border: '2px solid rgba(8,11,26,.9)', bgcolor: 'rgb(8,11,26)', color: '#fff' } }}>Learn More</Button>
                                    </Link>
                                </Grid>
                            </Grid>
                        </Container>
                    </Box>
                </div>
            </Slider>
        </div>
    );
}