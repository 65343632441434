import * as React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import { grey } from '@mui/material/colors';
import OpenBook from '../images/open-book.png';
import Planning from '../images/planning.png';
import Coding from '../images/coding.png';
import Testing from '../images/testing.png';
import ServiceList from './SectionServiceList';
import CourseList from './SectionCourseList';

const SEOPage = () => {
    return (
        <Container sx={{ my: 6 }}>
            <Grid container spacing={2} p={0}>
                <Grid item xs={12} md={8} sx={{ pt: "0 !important" }}>
                    <Box my={5}>
                        <Typography variant="h2" fontSize={28} fontWeight={600} mb={2}>Search Engine Optimization</Typography>
                        <Typography paragraph={true} sx={{ color: grey[800] }}>SEO stands for search engine optimization. It is the process of optimizing your website to make it more visible when people search for your requirements on Google.</Typography>
                    </Box>
                    <Box my={5}>
                        <Typography variant="h3" fontSize={24} fontWeight={500} mb={2}>About this Service</Typography>
                        <Typography paragraph={true} sx={{ color: grey[800] }}>SEO (search engine optimization) improves search engine rankings and generates more traffic. Many SEO services are focused on two elements: online visibility and search optimization.</Typography>
                        <Typography paragraph={true} sx={{ color: grey[800] }}>Corrupti deleniti atque magnam is es et quas stias cepturi sint aete non provident, similique sunt dunt ut labore et dolore magnam aliquam quaerat Neque porro quisquam est dolorem</Typography>
                    </Box>
                    <Box my={5}>
                        <Typography variant="h3" fontSize={24} fontWeight={500} mb={2}>How we Work</Typography>
                        <Typography paragraph={true} sx={{ color: grey[800] }}>SEO stands for Search Engine Optimization. It gets traffic from search engines via free, organic, or editorial search results. SEO aims to improve the position of your website on search result pages. Remember that the higher a website is listed, the more often it will be seen.</Typography>
                    </Box>
                    <Box my={5}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <Grid container spacing={2}>
                                    <Grid item xs={2}>
                                        <img src={OpenBook} width="100%" alt="Project Study Logo" />
                                    </Grid>
                                    <Grid item xs={10}>
                                        <Typography variant="h6" fontWeight={700} mb={1} fontSize={16}>Research</Typography>
                                        <Typography paragraph={true} sx={{ color: grey[800] }}>Create a list of targeted keywords and phrases. Examine competitive lists and other relevant industry sources.</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Grid container spacing={2}>
                                    <Grid item xs={2}>
                                        <img src={Planning} width="100%" alt="Wireframe Logo" />
                                    </Grid>
                                    <Grid item xs={10}>
                                        <Typography variant="h6" fontWeight={700} mb={1} fontSize={16}>Planning & Strategy</Typography>
                                        <Typography paragraph={true} sx={{ color: grey[800] }}>An analysis of meta sets/keywords and visible text to determine your position for search engines</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Grid container spacing={2}>
                                    <Grid item xs={2}>
                                        <img src={Coding} width="100%" alt="Coding Logo" />
                                    </Grid>
                                    <Grid item xs={10}>
                                        <Typography variant="h6" fontWeight={700} mb={1} fontSize={16}>Implementation</Typography>
                                        <Typography paragraph={true} sx={{ color: grey[800] }}>You should ensure that your content reaches the maximum number of people.</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Grid container spacing={2}>
                                    <Grid item xs={2}>
                                        <img src={Testing} width="100%" alt="Testing Logo" />
                                    </Grid>
                                    <Grid item xs={10}>
                                        <Typography variant="h6" fontWeight={700} mb={1} fontSize={16}>Monitoring</Typography>
                                        <Typography paragraph={true} sx={{ color: grey[800] }}>To determine search engine rankings and web traffic, analyze individual keywords to assess their effectiveness.</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                    <ServiceList/>
                    <CourseList/>
                </Grid>
            </Grid>
        </Container>
    )
}

export default SEOPage;