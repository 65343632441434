import * as React from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/system/Unstable_Grid';
import { grey } from '@mui/material/colors';
import websiteDevelopmentImage from '../images/website-development.jpg';
import SectionContact from '../Components/SectionContact';
import Footer from '../Components/Footer';
import WDPage from '../Components/WDPage';
import GoToTop from '../Components/GoToTop';
import ScrollButton from '../Components/ScrollButton';




const WebsiteDevelopment = () => {
    return (
      <>
      <Container maxWidth="xl" sx={{ minWidth: '100%', px: '0px !important' }}>
        <Box
          sx={{
            minwidth: '100%',
            textAlign: 'center',
            position: 'relative',
            color: grey[50],
            '&::before': {
              display: 'block',
              content: '""',
              width: '100%',
              height: '100%',
              position: 'absolute',
              top: '0',
              left: '0',
              zIndex: '-1',
              backgroundImage: `linear-gradient(to right,rgba(8,11,26,0.9),rgba(8,11,26,0.9)), url(${websiteDevelopmentImage})`,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat'
            }
          }}
        >
          <Container sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '460px' }}>
            <Box sx={{ display: 'block' }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h2" sx={{ fontSize: 'calc(1.4rem + 2.6vw)', fontWeight: 600 }}>Website Development</Typography>
                </Grid>
                <Grid item xs={12} md={6} mx="auto">
                  <Typography paragraph={true} sx={{ mx: 'auto', mb: '0px !important', fontSize: 14 }}>
                    India's best web development company <Box component="span" sx={{ fontWeight: 'bold' }}>Qbitt Technologies</Box> offers professional website design services for business growth and user-friendly and conversion-driven websites.
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Box>
      </Container>
      <WDPage/>
      <SectionContact/>
      <GoToTop />
      <Footer/>
      <ScrollButton />
      </>
    );
  };
  
  export default WebsiteDevelopment;