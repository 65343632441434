import * as React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { indigo, grey, orange } from '@mui/material/colors';
import teamBgImage from '../images/team-members-bg.jpg';
import Grid from '@mui/system/Unstable_Grid';
import Link from '@mui/material/Link';
import TeamSlider from './TeamSlider';

export default function SectionOurTeam() {
    return (
        <Container sx={{ minWidth: '100%', paddingLeft: '0px !important', paddingRight: '0px !important' }}>
            <Container
                sx={{
                    position: 'relative',
                    color: grey[50],
                    minWidth: '100%',
                    py: 10,
                    '&::before': {
                        display: 'block',
                        content: '""',
                        width: '100%',
                        height: '100%',
                        position: 'absolute',
                        top: '0',
                        left: '0',
                        zIndex: '-1',
                        backgroundImage: `linear-gradient(to right,rgba(8,11,26,0.9),rgba(8,11,26,0.9)), url(${teamBgImage})`,
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat'
                    }
                }}
            >

                <Grid maxWidth="xl" container spacing={2} py={6} px={2}>
                    <Grid item xs={12} md={4}>
                        <Typography variant="h2" sx={{ fontSize: 18 }}>OUR TEAM</Typography>
                        <Box
                            sx={{
                                width: '120px',
                                height: '2px',
                                bgcolor: indigo['A400'],
                                position: 'relative',
                                mt: 2,
                                '&::before': {
                                    display: 'block',
                                    content: '""',
                                    width: '34px',
                                    height: '7px',
                                    background: orange['A400'],
                                    borderRadius: '30px',
                                    position: 'absolute',
                                    top: '-2.5px'
                                },
                            }}>
                        </Box>
                        <Typography variant="h3" sx={{ mt: 4, fontSize: 36, fontWeight: '600' }}>We have great creative team</Typography>

                        <Typography paragraph={true} sx={{ mt: 4 }}>
                            Our industry experts are passionate about their work and have extensive experience in many applications. We have highly skilled people in their chosen field and many years of experience. Special projects are handled with great effort. With expert knowledge and well-thought-out strategies, we perform. A team made up of experts from various organizations. Each person has their own experience and knowledge. Our industry experts bring their vast experience and expertise to your business!
                        </Typography>
                        <Link href="/Team" underline="hover" sx={{ color: grey[50], fontWeight: '500', position: 'relative','&:hover::after':{right: '-25px'}, '&::after': { display: 'block', content: '"\\00BB"', position: 'absolute', top: '-8px', right: '-20px', fontSize: '26px', color: orange[600], transition: '.2s ease-in-out' } }}>
                            {'More Details'}
                        </Link>
                    </Grid>
                    <Grid item xs={12} md={8} sx={{ position: 'relative', color: grey[900] }}>
                        <TeamSlider />
                    </Grid>
                </Grid>
            </Container>
        </Container>
    );
};