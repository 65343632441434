import * as React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import { grey } from '@mui/material/colors';
import OpenBook from '../images/open-book.png';
import Wireframe from '../images/wireframe.png';
import Coding from '../images/coding.png';
import Testing from '../images/testing.png';
import ServiceList from './SectionServiceList';
import CourseList from './SectionCourseList';

const PICPage = () => {
    return (
        <Container sx={{ my: 6 }}>
            <Grid container spacing={2} p={0}>
                <Grid item xs={12} md={8} sx={{ pt: "0 !important" }}>
                    <Box my={5}>
                        <Typography variant="h2" fontSize={28} fontWeight={600} mb={2}>Professional IT Courses</Typography>
                        <Typography paragraph={true} sx={{ color: grey[800] }}>Learn with us to become a qualified IT professional,  We provide a variety of IT courses, and experts provide support and tutoring for immersive learning.</Typography>
                    </Box>
                    <Box my={5}>
                        <Typography variant="h3" fontSize={24} fontWeight={500} mb={2}>About this Service</Typography>
                        <Typography paragraph={true} sx={{ color: grey[800] }}>A professional course teaches practical skills that will make you job-ready. A degree program, on the other hand, aims to give you a solid academic foundation and make you job-ready.</Typography>
                        <Typography paragraph={true} sx={{ color: grey[800] }}>Corrupti deleniti atque magnam is es et quas stias cepturi sint aete non provident, similique sunt dunt ut labore et dolore magnam aliquam quaerat Neque porro quisquam est dolorem</Typography>
                    </Box>
                    <Box my={5}>
                        <Typography variant="h3" fontSize={24} fontWeight={500} mb={2}>How we Work</Typography>
                        <Typography paragraph={true} sx={{ color: grey[800] }}>We offer computer knowledge and exceptional troubleshooting skills. We were able to get to know you, improve your IT skills, give you certification, help you gain experience, and help with your interview skills.</Typography>
                    </Box>
                    <Box my={5}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <Grid container spacing={2}>
                                    <Grid item xs={2}>
                                        <img src={OpenBook} width="100%" alt="Project Study Logo" />
                                    </Grid>
                                    <Grid item xs={10}>
                                        <Typography variant="h6" fontWeight={700} mb={1} fontSize={16}>Guidance</Typography>
                                        <Typography paragraph={true} sx={{ color: grey[800] }}>First we gather your project requirements and analyze them thoroughly for implementation and plan accordingly.</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Grid container spacing={2}>
                                    <Grid item xs={2}>
                                        <img src={Wireframe} width="100%" alt="Wireframe Logo" />
                                    </Grid>
                                    <Grid item xs={10}>
                                        <Typography variant="h6" fontWeight={700} mb={1} fontSize={16}>Online Classes</Typography>
                                        <Typography paragraph={true} sx={{ color: grey[800] }}>Once the project planning is completed our developer team starts implementing the project.</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Grid container spacing={2}>
                                    <Grid item xs={2}>
                                        <img src={Coding} width="100%" alt="Coding Logo" />
                                    </Grid>
                                    <Grid item xs={10}>
                                        <Typography variant="h6" fontWeight={700} mb={1} fontSize={16}>Interview Preparation</Typography>
                                        <Typography paragraph={true} sx={{ color: grey[800] }}>After the Implementation of project we continue testing to ensure that the software is defect & bugs free.</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Grid container spacing={2}>
                                    <Grid item xs={2}>
                                        <img src={Testing} width="100%" alt="Testing Logo" />
                                    </Grid>
                                    <Grid item xs={10}>
                                        <Typography variant="h6" fontWeight={700} mb={1} fontSize={16}>Job Assistance</Typography>
                                        <Typography paragraph={true} sx={{ color: grey[800] }}>Finally we deploy the software after appropriate testing and approval to release.</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                    <ServiceList/>
                    <CourseList/>
                </Grid>
            </Grid>
        </Container>
    )
}

export default PICPage;