import * as React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import { grey } from '@mui/material/colors';
import OpenBook from '../images/open-book.png';
import Wireframe from '../images/wireframe.png';
import Coding from '../images/coding.png';
import Testing from '../images/testing.png';
import ServiceList from './SectionServiceList';
import CourseList from './SectionCourseList';

const WDPage = () => {
    return (
        <Container sx={{ my: 6 }}>
            <Grid container spacing={2} p={0}>
                <Grid item xs={12} md={8} sx={{ pt: "0 !important" }}>
                    <Box my={5}>
                        <Typography variant="h2" fontSize={28} fontWeight={600} mb={2}>Website Development</Typography>
                        <Typography paragraph={true} sx={{ color: grey[800] }}>India's best web development company offers professional website design services for business growth and user-friendly and conversion-driven websites.</Typography>
                    </Box>
                    <Box my={5}>
                        <Typography variant="h3" fontSize={24} fontWeight={500} mb={2}>About this Service</Typography>
                        <Typography paragraph={true} sx={{ color: grey[800] }}>Qbitt Technologies is a web and software development company based in India. They offer a variety of website design services to SMEs and large businesses.</Typography>
                        <Typography paragraph={true} sx={{ color: grey[800] }}>Our skilled team of website developers and digital marketing specialists uses the most recent technologies and proven strategies to create user-engaging websites and dynamic web applications for businesses and to promote them to increase customer reach.</Typography>
                    </Box>
                    <Box my={5}>
                        <Typography variant="h3" fontSize={24} fontWeight={500} mb={2}>How we Work</Typography>
                        <Typography paragraph={true} sx={{ color: grey[800] }}>Setting and forgetting when building a website is not a good idea. Expectations of customers are high and there is high competition. Only a few seconds are available to grab users' attention and give you a competitive edge.</Typography>
                    </Box>
                    <Box my={5}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <Grid container spacing={2}>
                                    <Grid item xs={2}>
                                        <img src={OpenBook} width="100%" alt="Project Study Logo" />
                                    </Grid>
                                    <Grid item xs={10}>
                                        <Typography variant="h6" fontWeight={700} mb={1} fontSize={16}>Project Study</Typography>
                                        <Typography paragraph={true} sx={{ color: grey[800] }}>The report was compiled using available data, forecasts by experts, and other project management tools.</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Grid container spacing={2}>
                                    <Grid item xs={2}>
                                        <img src={Wireframe} width="100%" alt="Wireframe Logo" />
                                    </Grid>
                                    <Grid item xs={10}>
                                        <Typography variant="h6" fontWeight={700} mb={1} fontSize={16}>Website & Wireframe Design</Typography>
                                        <Typography paragraph={true} sx={{ color: grey[800] }}>A website wireframe is a row representation of a website. It focuses on the content, layout, as well as behavior.</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Grid container spacing={2}>
                                    <Grid item xs={2}>
                                        <img src={Coding} width="100%" alt="Coding Logo" />
                                    </Grid>
                                    <Grid item xs={10}>
                                        <Typography variant="h6" fontWeight={700} mb={1} fontSize={16}>Website Development</Typography>
                                        <Typography paragraph={true} sx={{ color: grey[800] }}>We specialize in branding, website & application design & development. Our forte is prompt delivery and quality products without compromise.</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Grid container spacing={2}>
                                    <Grid item xs={2}>
                                        <img src={Testing} width="100%" alt="Testing Logo" />
                                    </Grid>
                                    <Grid item xs={10}>
                                        <Typography variant="h6" fontWeight={700} mb={1} fontSize={16}>Testing & Deployment</Typography>
                                        <Typography paragraph={true} sx={{ color: grey[800] }}>TIP (testing in production) is a practice that allows a website to be thoroughly tested before it goes live.</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                    <ServiceList/>
                    <CourseList/>
                </Grid>
            </Grid>
        </Container>
    )
}

export default WDPage;