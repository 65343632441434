import * as React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { grey } from '@mui/material/colors';
import Grid from '@mui/system/Unstable_Grid';
import Link from '@mui/material/Link';
// import profileImage from '../images/Client-02.jpg';
import rahulGajbhiyeImage from '../images/rahul-gajbhiye.jpg';
import shruteshBhowateImage from '../images/shrutesh-bhowate.jpg';
import vinayKumarImage from '../images/vinay-kumar.png';
import roshanSahareImage from '../images/roshan-sahare.png';
import './TeamSlider.css';

export default function SectionTeamMembers() {
    return (
        <Container maxWidth="xl" sx={{ py: 6, bgcolor: grey[100] }}>
            <Container maxWidth="lg">
                <Box sx={{ flexGrow: 1, my: 5 }}>
                    <Grid container spacing={4}>
                        <Grid xs={12} sm={6} md={3}>
                            <Link href="Team/Rahul-Gajbhiye" underline="none" color="inherit">
                                <Box className='team-member' sx={{ bgcolor: 'white', textAlign: 'center', transition: '.3s ease-in-out', '&:hover': { transform: 'translateY(-10px)', boxShadow: 15 } }}>
                                    <img src={rahulGajbhiyeImage} alt="Rahul Gajbhiye" width="100%" />
                                    <Typography variant="h5" sx={{ px: 2, pt: 2, fontSize: 18, fontWeight: 600 }}>Rahul Gajbhiye</Typography>
                                    <Typography variant="h6" sx={{ px: 2, pb: 2, fontSize: 14, fontWeight: 400 }}>CEO</Typography>
                                </Box>
                            </Link>
                        </Grid>
                        <Grid xs={12} sm={6} md={3}>
                            <Link href="Team/Vinay-Kumar" underline="none" color="inherit">
                                <Box className='team-member' sx={{ bgcolor: 'white', textAlign: 'center', transition: '.3s ease-in-out', '&:hover': { transform: 'translateY(-10px)', boxShadow: 15 } }}>
                                    <img src={vinayKumarImage} alt="Vinay Kumar" width="100%" />
                                    <Typography variant="h5" sx={{ px: 2, pt: 2, fontSize: 18, fontWeight: 600 }}>Vinay Kumar</Typography>
                                    <Typography variant="h6" sx={{ px: 2, pb: 2, fontSize: 14, fontWeight: 400 }}>Managing Director</Typography>
                                </Box>
                            </Link>
                        </Grid>
                        <Grid xs={12} sm={6} md={3}>
                            <Link href="Team/Roshan-Sahare" underline="none" color="inherit">
                                <Box className='team-member' sx={{ bgcolor: 'white', textAlign: 'center', transition: '.3s ease-in-out', '&:hover': { transform: 'translateY(-10px)', boxShadow: 15 } }}>
                                    <img src={roshanSahareImage} alt="Roshan Sahare" width="100%" />
                                    <Typography variant="h5" sx={{ px: 2, pt: 2, fontSize: 18, fontWeight: 600 }}>Roshan Sahare</Typography>
                                    <Typography variant="h6" sx={{ px: 2, pb: 2, fontSize: 14, fontWeight: 400 }}>Vice President</Typography>
                                </Box>
                            </Link>
                        </Grid>
                        <Grid xs={12} sm={6} md={3}>
                            <Link href="Team/Shrutesh-Bhowate" underline="none" color="inherit">
                                <Box className='team-member' sx={{ bgcolor: 'white', textAlign: 'center', transition: '.3s ease-in-out', '&:hover': { transform: 'translateY(-10px)', boxShadow: 15 } }}>
                                    <img src={shruteshBhowateImage} alt="Shrutesh Bhowate" width="100%" />
                                    <Typography variant="h5" sx={{ px: 2, pt: 2, fontSize: 18, fontWeight: 600 }}>Shrutesh Bhowate</Typography>
                                    <Typography variant="h6" sx={{ px: 2, pb: 2, fontSize: 14, fontWeight: 400 }}>Cheif Marketing Officer</Typography>
                                </Box>
                            </Link>
                        </Grid>
                        {/* <Grid xs={12} sm={6} md={3}>
                            <Link href="Team/Dhaneshwar-Mondal" underline="none" color="inherit">
                                <Box className='team-member' sx={{ bgcolor: 'white', textAlign: 'center', transition: '.3s ease-in-out', '&:hover': { transform: 'translateY(-10px)', boxShadow: 15 } }}>
                                    <img src={profileImage} alt="Dhaneshwar Mondal" width="100%" />
                                    <Typography variant="h5" sx={{ px: 2, pt: 2, fontSize: 18, fontWeight: 600 }}>Dhaneshwar Mondal</Typography>
                                    <Typography variant="h6" sx={{ px: 2, pb: 2, fontSize: 14, fontWeight: 400 }}>Cheif Technology Officer</Typography>
                                </Box>
                            </Link>
                        </Grid>
                        <Grid xs={12} sm={6} md={3}>
                            <Link href="Team/Hema" underline="none" color="inherit">
                                <Box className='team-member' sx={{ bgcolor: 'white', textAlign: 'center', transition: '.3s ease-in-out', '&:hover': { transform: 'translateY(-10px)', boxShadow: 15 } }}>
                                    <img src={profileImage} alt="Hema" width="100%" />
                                    <Typography variant="h5" sx={{ px: 2, pt: 2, fontSize: 18, fontWeight: 600 }}>Hema</Typography>
                                    <Typography variant="h6" sx={{ px: 2, pb: 2, fontSize: 14, fontWeight: 400 }}>HR & Admin</Typography>
                                </Box>
                            </Link>
                        </Grid>
                        <Grid xs={12} sm={6} md={3}>
                            <Link href="Team/Sandeep-Tijare" underline="none" color="inherit">
                                <Box className='team-member' sx={{ bgcolor: 'white', textAlign: 'center', transition: '.3s ease-in-out', '&:hover': { transform: 'translateY(-10px)', boxShadow: 15 } }}>
                                    <img src={profileImage} alt="Sandeep Tijare" width="100%" />
                                    <Typography variant="h5" sx={{ px: 2, pt: 2, fontSize: 18, fontWeight: 600 }}>Sandeep Tijare</Typography>
                                    <Typography variant="h6" sx={{ px: 2, pb: 2, fontSize: 14, fontWeight: 400 }}>Project Manager</Typography>
                                </Box>
                            </Link>
                        </Grid>
                        <Grid xs={12} sm={6} md={3}>
                            <Link href="Team/Trivesh-Wasnik" underline="none" color="inherit">
                                <Box className='team-member' sx={{ bgcolor: 'white', textAlign: 'center', transition: '.3s ease-in-out', '&:hover': { transform: 'translateY(-10px)', boxShadow: 15 } }}>
                                    <img src={profileImage} alt="Trivesh Wasnik" width="100%" />
                                    <Typography variant="h5" sx={{ px: 2, pt: 2, fontSize: 18, fontWeight: 600 }}>Trivesh-wasnik</Typography>
                                    <Typography variant="h6" sx={{ px: 2, pb: 2, fontSize: 14, fontWeight: 400 }}>Full Stack Developer</Typography>
                                </Box>
                            </Link>
                        </Grid> */}
                    </Grid>
                </Box>
            </Container>
        </Container>
    );
};