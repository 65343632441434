import * as React from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/system/Unstable_Grid';
import { grey } from '@mui/material/colors';
import professionalItCoursesImage from '../images/professional-it-courses.jpg';
import SectionContact from '../Components/SectionContact';
import Footer from '../Components/Footer';
import fullStackWebDeveloperImage from '../images/full-stack-web-developer.jpg';
import CourseList from '../Components/SectionCourseList';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import { Divider } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

import { styled } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import GoToTop from '../Components/GoToTop';
import ScrollButton from '../Components/ScrollButton';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<AddIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));


const FullStackWebDeveloper = () => {
  const [expanded, setExpanded] = React.useState('panel0');
  const introduction = ['Introduction to Web', 'What is website', 'Types of websites', 'Website development Approach', 'Website Development Tools', 'Introduction to domain & hosting'];
  const htmlContent = ['Introduction to HTML', 'HTML Elements', 'HTML Attributes', 'HTML Graphics', 'HTML Media'];
  const cssContent = ['Introduction to CSS', 'Types of CSS', 'CSS Selectors', 'CSS Properties', 'Responsive CSS'];
  const javascriptContent = ['Introduction to Javascript', 'Fundamentals of Javascript', 'Javascript Classes', 'Javascript Objects', 'Javascript Functions', 'Javascript DOM'];
  const phpContent = ['Introduction to Php', 'Php Fundamentals', 'Php Form Handling', 'Php Database Connectivity', 'Php Sessions'];
  const wordpress = ['Introduction to wordpress', 'Installation of wordpress', 'Wordpress Theme Development', 'Custom Metabox', 'Plugin Development', 'Child Theme Development', 'Project Deployment to Web Server'];
  const mySql = ['Introduction to MySQL', 'Introduction to Wamp Server', 'Installation of Wamp Server', 'MySQL CRUD Operations'];
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  return (
    <>
      <Container maxWidth="xl" sx={{ minWidth: '100%', px: '0px !important' }}>
        <Box
          sx={{
            minwidth: '100%',
            textAlign: 'center',
            position: 'relative',
            color: grey[50],
            '&::before': {
              display: 'block',
              content: '""',
              width: '100%',
              height: '100%',
              position: 'absolute',
              top: '0',
              left: '0',
              zIndex: '-1',
              backgroundImage: `linear-gradient(to right,rgba(8,11,26,0.9),rgba(8,11,26,0.9)), url(${professionalItCoursesImage})`,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat'
            }
          }}
        >
          <Container sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '460px' }}>
            <Box sx={{ display: 'block' }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h2" sx={{ fontSize: 'calc(1.4rem + 2.6vw)', fontWeight: 600 }}>Full Stack Web Developer</Typography>
                </Grid>
                <Grid item xs={12} md={6} mx="auto">
                  <Typography paragraph={true} sx={{ mx: 'auto', mb: '0px !important', fontSize: 14 }}>
                    <Box component="span" sx={{ fontWeight: 'bold' }}>Qbitt Technologies</Box> always try to provide the best IT Solutions for Clients to grow up their Business very sharply and smoothly.
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Box>
      </Container>
      <Container maxWidth="lg" sx={{ my: 8 }}>
        <img src={fullStackWebDeveloperImage} alt='Full Stack Developer' width="100%"></img>
      </Container>
      <Container maxWidth="lg" sx={{ mb: 6 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8} >
            <Typography variant="h4" fontWeight={600} mb={3} >Full Stack Web Developer</Typography>
            <Typography paragraph='true' mb={3}>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquam aperiam assumenda culpa cumque debitis dignissimos, dolor dolores doloribus ducimus fuga iusto magni maiores minus nam numquam officiis provident quasi quibusdam quos ratione rem saepe sequi sit unde ut velit vitae. Amet ea error expedita, laboriosam maxime officiis porro ut velit.</Typography>
            <Typography variant="h6" fontWeight={600} mb={3} >Skills You Will Learn</Typography>
            <Grid container spacing={2} mb={3}>
              <Grid item md={6} py={0}>
                <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', py: 0 }}>
                  <ListItem>
                    <ListItemIcon>
                      <CheckIcon sx={{ fontSize: 20, color: '#4caf50', border: 1 }} />
                    </ListItemIcon>
                    <ListItemText primary="Introduction to Web Development" />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <CheckIcon sx={{ fontSize: 20, color: '#4caf50', border: 1 }} />
                    </ListItemIcon>
                    <ListItemText primary="HTML5 & CSS3" />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <CheckIcon sx={{ fontSize: 20, color: '#4caf50', border: 1 }} />
                    </ListItemIcon>
                    <ListItemText primary="Javascript" />
                  </ListItem>
                </List>
              </Grid>
              <Grid item md={6} py={0}>
                <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', py: 0 }}>
                  <ListItem>
                    <ListItemIcon>
                      <CheckIcon sx={{ fontSize: 20, color: '#4caf50', border: 1 }} />
                    </ListItemIcon>
                    <ListItemText primary="Php" />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <CheckIcon sx={{ fontSize: 20, color: '#4caf50', border: 1 }} />
                    </ListItemIcon>
                    <ListItemText primary="Wordpress" />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <CheckIcon sx={{ fontSize: 20, color: '#4caf50', border: 1 }} />
                    </ListItemIcon>
                    <ListItemText primary="MySQL" />
                  </ListItem>
                </List>
              </Grid>
            </Grid>
            <Typography variant='h6' fontWeight={600} mb={3}>Who Should Learn</Typography>
            <Typography paragraph='true'>
              <Box sx={{ border: 1, borderColor: grey[500], display: 'inline-block', px: 2, py: 1, mr: 3, mb: 3, bgcolor: grey[300] }}>Anyone</Box>
              {/* <Box sx={{ border: 1, borderColor: grey[500], display: 'inline-block', px: 2, py: 1, mr: 3, mb: 3, bgcolor: grey[300] }}>Business Analyst</Box>
              <Box sx={{ border: 1, borderColor: grey[500], display: 'inline-block', px: 2, py: 1, mr: 3, mb: 3, bgcolor: grey[300] }}>Business Analyst</Box> */}
            </Typography>



            <Typography variant='h6' fontWeight={600} mb={3}>What You Will Learn</Typography>
            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
              <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                <Typography>Introduction</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <List>
                  {introduction.map((topic) => (
                    <ListItem disablePadding>
                      <ListItemIcon>
                        <KeyboardArrowRightIcon />
                      </ListItemIcon>
                      <ListItemText primary={topic} />
                    </ListItem>
                  ))}
                </List>
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
              <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                <Typography>HTML5</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <List>
                  {htmlContent.map((topic) => (
                    <ListItem disablePadding>
                      <ListItemIcon>
                        <KeyboardArrowRightIcon />
                      </ListItemIcon>
                      <ListItemText primary={topic} />
                    </ListItem>
                  ))}
                </List>
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
              <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                <Typography>CSS3</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <List>
                  {cssContent.map((topic) => (
                    <ListItem disablePadding>
                      <ListItemIcon>
                        <KeyboardArrowRightIcon />
                      </ListItemIcon>
                      <ListItemText primary={topic} />
                    </ListItem>
                  ))}
                </List>
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
              <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
                <Typography>Javascript</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <List>
                  {javascriptContent.map((topic) => (
                    <ListItem disablePadding>
                      <ListItemIcon>
                        <KeyboardArrowRightIcon />
                      </ListItemIcon>
                      <ListItemText primary={topic} />
                    </ListItem>
                  ))}
                </List>
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
              <AccordionSummary aria-controls="panel5d-content" id="panel5d-header">
                <Typography>Php</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <List>
                  {phpContent.map((topic) => (
                    <ListItem disablePadding>
                      <ListItemIcon>
                        <KeyboardArrowRightIcon />
                      </ListItemIcon>
                      <ListItemText primary={topic} />
                    </ListItem>
                  ))}
                </List>
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
              <AccordionSummary aria-controls="panel6d-content" id="panel6d-header">
                <Typography>Wordpress</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <List>
                  {wordpress.map((topic) => (
                    <ListItem disablePadding>
                      <ListItemIcon>
                        <KeyboardArrowRightIcon />
                      </ListItemIcon>
                      <ListItemText primary={topic} />
                    </ListItem>
                  ))}
                </List>
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
              <AccordionSummary aria-controls="panel7d-content" id="panel7d-header">
                <Typography>MySQL</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <List>
                  {mySql.map((topic) => (
                    <ListItem disablePadding>
                      <ListItemIcon>
                        <KeyboardArrowRightIcon />
                      </ListItemIcon>
                      <ListItemText primary={topic} />
                    </ListItem>
                  ))}
                </List>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12} md={4} >
            <Box p={2} boxShadow={3}>
              <Typography variant='h6'>About Course</Typography>
              <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                <ListItem>
                  <ListItemIcon>
                    <AccessTimeIcon sx={{ fontSize: 34 }} />
                  </ListItemIcon>
                  <ListItemText primary="180 Days" secondary="Online lectures" />
                </ListItem>
                <Divider width={'90%'} sx={{ mx: 'auto' }} />
                <ListItem>
                  <ListItemIcon>
                    <TextSnippetIcon sx={{ fontSize: 34 }} />
                  </ListItemIcon>
                  <ListItemText primary="Completion Certificate" secondary="Awarded on completion" />
                </ListItem>
                <Divider width={'90%'} sx={{ mx: 'auto' }} />
                <ListItem>
                  <ListItemIcon>
                    <OndemandVideoIcon sx={{ fontSize: 34 }} />
                  </ListItemIcon>
                  <ListItemText primary="365 Days Access" secondary="To video lectures" />
                </ListItem>
              </List>
            </Box>
            <CourseList />
          </Grid>
        </Grid>
      </Container>
      <SectionContact />
      <GoToTop />
      <Footer />
      <ScrollButton />
    </>
  );
};

export default FullStackWebDeveloper;