import React, { useRef } from 'react';
import { useState } from "react";
import emailjs from '@emailjs/browser';
import Container from '@mui/material/Container';
import Grid from '@mui/system/Unstable_Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { grey, indigo, orange } from '@mui/material/colors';
import Button from '@mui/material/Button';
import Input from '@mui/material/Input';
import Footer from './Components/Footer';
import FacebookIcon from '@mui/icons-material/Facebook';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import IconButton from '@mui/material/IconButton';
import GoToTop from './Components/GoToTop';
import ScrollButton from './Components/ScrollButton';
// import ReactDOM from 'react-dom/client';

const Contact = () => {
  const form = useRef();
  const [inputs, setInputs] = useState({});

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({ ...values, [name]: value }))
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    emailjs.sendForm('smtp', 'qbitt_template', form.current, 'XB1mQdKQaRPFEvn4v')
      .then((result) => {
        document.getElementById('formMessage').innerHTML = "<p style='color: #04AA6D; font-weight: bold'>Message sent successfully.</p>";
      }, (error) => {
        document.getElementById('formMessage').innerHTML = "<p style='color: crimson; font-weight: bold'>Error sending message.</Typography>";
      });
  }


  return (
    <>
      <Container maxWidth="lg" sx={{ my: 15 }}>
        <Grid container spacing={2} sx={{ boxShadow: '0 10px 60px 0 rgb(71 74 182 / 12%)' }}>
          <Grid item xs={12} md={8} py={5} px={3}>
            <Typography variant="h2" sx={{ fontSize: 28, fontWeight: 600 }}>Send us a Message</Typography>
            <Box
              sx={{
                width: '120px',
                height: '2px',
                bgcolor: indigo['A400'],
                position: 'relative',
                mt: 2,
                '&::before': {
                  display: 'block',
                  content: '""',
                  width: '34px',
                  height: '7px',
                  background: orange['A400'],
                  borderRadius: '30px',
                  position: 'absolute',
                  top: '-2.5px'
                },
              }}>
            </Box>
            <form ref={form} onSubmit={handleSubmit}>
              <Grid container spacing={4} py={5}>
                <Grid item xs={12} md={6}>
                  <Input
                    required
                    type="text"
                    name="fname"
                    value={inputs.fname || ""}
                    onChange={handleChange}
                    disableUnderline={true}
                    placeholder={'First Name'}
                    sx={{ border: 1, px: 2, py: 1, width: '100%', borderColor: grey[300], focused: { borderColor: '#000 !important' } }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Input
                    required
                    type='text'
                    name="lname"
                    value={inputs.lname || ""}
                    onChange={handleChange}
                    disableUnderline={true}
                    placeholder={'Last Name'}
                    sx={{ border: 1, px: 2, py: 1, width: '100%', borderColor: grey[300] }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Input
                    required
                    type="email"
                    name="email"
                    value={inputs.email || ""}
                    onChange={handleChange}
                    disableUnderline={true}
                    placeholder={'Email Address'}
                    sx={{ border: 1, px: 2, py: 1, width: '100%', borderColor: grey[300] }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Input
                    required
                    type='text'
                    name="phone"
                    value={inputs.phone || ""}
                    onChange={handleChange}
                    disableUnderline={true}
                    placeholder={'Phone No'}
                    sx={{ border: 1, px: 2, py: 1, width: '100%', borderColor: grey[300] }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Input
                    required
                    type="textarea"
                    multiline={true}
                    rows={7}
                    name="msg"
                    value={inputs.msg || ""}
                    onChange={handleChange}
                    disableUnderline={true}
                    placeholder={'Write Your Message'}
                    sx={{ border: 1, px: 2, py: 1, width: '100%', borderColor: grey[300] }}
                  />
                </Grid>
              </Grid>
              <Box id='formMessage' sx={{ px: 2 }}></Box>
              <Button type="submit" variant="outlined" sx={{ borderRadius: 30, color: '#000', border: '2px solid #000', fontSize: 16, fontWeight: 600, px: 6, py: 1.5, '&:hover': { border: '2px solid rgba(8,11,26,.9)', bgcolor: 'rgb(8,11,26)', color: '#fff' } }}>Send Message</Button>
            </form>
          </Grid>
          <Grid item xs={12} md={4} bgcolor={'rgb(8,11,26)'} py={10}>
            <Box display="flex" color={grey[50]} alignItems="center" justifyContent="center" height="100%" px={3}>
              <Box px={5}>
                <Typography variant="h2" fontSize={28} fontWeight={600} mb={5}>Contact Info</Typography>
                <Typography paragraph={true} mb={0.1}>Plot No 190, NIT Layout,</Typography>
                <Typography paragraph={true} mb={0.1}>Nalanda Nagar, Nari Road,</Typography>
                <Typography paragraph={true}>Nagpur 440026, India</Typography>
                <Typography paragraph={true}>(+91) 7020171320</Typography>
                <IconButton
                  size='large'
                  color="inherit"
                  sx={{ ml: -2 }}
                  href="https://www.facebook.com/people/Qbitt-Technologies/100078775545401/"
                >
                  <FacebookIcon />
                </IconButton>
                <IconButton
                  size='large'
                  color="inherit"
                  href="https://wa.me/917020171320"
                >
                  <WhatsAppIcon />
                </IconButton>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
      <GoToTop />
      <Footer />
      <ScrollButton />
    </>
  );
};

export default Contact;