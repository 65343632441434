import * as React from "react";
import Slider from "react-slick";
import Box from '@mui/material/Box';
import Grid from '@mui/system/Unstable_Grid';
import Typography from '@mui/material/Typography';
import Stefen from '../images/Client-01.png';
import Kathreine from '../images/katherine-langford.png';
import Visha from '../images/visha-yanovleva.png';
import Billy from '../images/billy-simmons.png';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import './TestimonialSlider.css';




export default function TestimonialSlider() {
    const settings = {
        autoplay: true,
        dots: true,
        arrows: false,
        infinite: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        speed: 500,
        responsive: [
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <div>
            <Slider {...settings} className="testimonial">
                <div>
                    <Box sx={{ widht: '100%', bgcolor: '#fff', m: 1, p: 3 }}>
                        <Grid container spacing={4}>
                            <Grid xs={12} md={4} sx={{ position: 'relative' }}>
                                <Box sx={{ position: 'absolute', display: 'block', top: '50%', left: '50%', transform: 'translate(-50%,-50%)', width: 100, height: 100 }}>
                                    <img src={Kathreine} alt="Qbitt Technologies Services" width="100%" />
                                </Box>
                            </Grid>
                            <Grid xs={12} md={8}>
                                <Box>
                                    <FormatQuoteIcon sx={{ fontSize: 44, transform: 'rotate(180deg)', ml: -1 }} />
                                    <Typography paragraph={true}>Qbitt was a company we started working with to revamp our old website, make it more appealing to our customers, and keep us competitive in our industry. We needed a website that we could manage without learning the code. Our previous website did not have a content management system. Qbitt did it flawlessly, thanks to the Qbitt Team.</Typography>
                                    <Typography variant="h5" sx={{ fontWeight: 700, fontSize: 16 }}>Katherine Longford</Typography>
                                    <Typography variant="h6" sx={{ fontWeight: 700, fontSize: 12 }}>Fourth & Wall Inc.</Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </div>
                <div>
                    <Box sx={{ widht: '100%', bgcolor: '#fff', m: 1, p: 3 }}>
                        <Grid container spacing={4}>
                            <Grid xs={12} md={4} sx={{ position: 'relative' }}>
                                <Box sx={{ position: 'absolute', display: 'block', top: '50%', left: '50%', transform: 'translate(-50%,-50%)', width: 100, height: 100 }}>
                                    <img src={Visha} alt="Qbitt Technologies Services" width="100%" />
                                </Box>
                            </Grid>
                            <Grid xs={12} md={8}>
                                <Box>
                                    <FormatQuoteIcon sx={{ fontSize: 44, transform: 'rotate(180deg)', ml: -1 }} />
                                    <Typography paragraph={true}>The Qbitt team made my experience 100% positive. Every member of the Qbitt team was attentive to my needs, professional, and open-minded. They were always available to me when I needed them. This company was initially the one I contacted to build my website. However, I have since worked on many creative projects with them. Thanks, Qbitt team.</Typography>
                                    <Typography variant="h5" sx={{ fontWeight: 700, fontSize: 16 }}>Visha Yanovleva</Typography>
                                    <Typography variant="h6" sx={{ fontWeight: 700, fontSize: 12 }}>Executive Director at Bence Corporation.</Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </div>
                <div>
                    <Box sx={{ widht: '100%', bgcolor: '#fff', m: 1, p: 3 }}>
                        <Grid container spacing={4}>
                            <Grid xs={12} md={4} sx={{ position: 'relative' }}>
                                <Box sx={{ position: 'absolute', display: 'block', top: '50%', left: '50%', transform: 'translate(-50%,-50%)', width: 100, height: 100 }}>
                                    <img src={Stefen} alt="Qbitt Technologies Services" width="100%" />
                                </Box>
                            </Grid>
                            <Grid xs={12} md={8}>
                                <Box>
                                    <FormatQuoteIcon sx={{ fontSize: 44, transform: 'rotate(180deg)', ml: -1 }} />
                                    <Typography paragraph={true}>Qbitt Technologies played an essential role in developing and maintaining our software system. They were quickly integrated into the core team and created terrific features that meet the highest performance and demand demands. They have the best work ethic and business cooperation.</Typography>
                                    <Typography variant="h5" sx={{ fontWeight: 700, fontSize: 16 }}>Stefen Hardie</Typography>
                                    <Typography variant="h6" sx={{ fontWeight: 700, fontSize: 12 }}>Consulting Professional at Sammon Point, Inc.</Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </div>
                <div>
                    <Box sx={{ widht: '100%', bgcolor: '#fff', m: 1, p: 3 }}>
                        <Grid container spacing={4}>
                            <Grid xs={12} md={4} sx={{ position: 'relative' }}>
                                <Box sx={{ position: 'absolute', display: 'block', top: '50%', left: '50%', transform: 'translate(-50%,-50%)', width: 100, height: 100 }}>
                                    <img src={Billy} alt="Qbitt Technologies Services" width="100%" />
                                </Box>
                            </Grid>
                            <Grid xs={12} md={8}>
                                <Box>
                                    <FormatQuoteIcon sx={{ fontSize: 44, transform: 'rotate(180deg)', ml: -1 }} />
                                    <Typography paragraph={true}>Qbitt has been a partner of mine for the past two years, helping me with event bookings and ticket sales online. Qbitt Technologies expertise has been a great asset to my business. They created many modules that extended our web-based software and an iPhone app to help our promoters manage tickets.</Typography>
                                    <Typography variant="h5" sx={{ fontWeight: 700, fontSize: 16 }}>Billy Simsons</Typography>
                                    <Typography variant="h6" sx={{ fontWeight: 700, fontSize: 12 }}>5th Wave Inc</Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </div>
            </Slider>
        </div>
    );
}