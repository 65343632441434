import * as React from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/system/Unstable_Grid';
import { grey } from '@mui/material/colors';
import professionalItCoursesImage from '../images/professional-it-courses.jpg';
import SectionContact from '../Components/SectionContact';
import Footer from '../Components/Footer';
import salesForceImage from '../images/salesforce.jpg';
import CourseList from '../Components/SectionCourseList';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import { Divider } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

import { styled } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import GoToTop from '../Components/GoToTop';
import ScrollButton from '../Components/ScrollButton';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<AddIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));


const SalesForce = () => {
  const [expanded, setExpanded] = React.useState('panel0');
  const salesforceFundamentals = ['Introduction to Web Technologies', 'Introduction to SalesForce Technologies'];
  const apexFundamentals = ['Variable & Datatypes', 'Class', 'Collections', 'Operators', 'Type Conversion'];
  const controlStatements = ['If Else', 'Switch', 'Do While', 'While Loop', 'For Loop', 'Break & Continue'];
  const classesAndMethods = ['OOPS in Apex', 'Access Modifiers', 'Apex Methods', 'Static Keyword', 'Naming Conventions', 'Constructors', 'Initialization Block', 'Wrapper Classes'];
  const apexTesting = ['Introduction to Apex Testing', 'Test Class', 'Assert Statements', 'Apex Tests'];
  const soql = ['Introduction to SOQL', 'Operators', 'Clauses', 'Literals', 'Relationships', 'SOQL Bind', 'Dynamic SOQL'];
  const dml = ['Introduction to DML', 'Create', 'Insert', 'Update', 'Delete', 'Select'];
  const exceptionHandling = ['Introduction to Exception Handling', 'Try, Catch, Finally', 'Throw Exceptions', 'Custom Exceptions'];
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  return (
    <>
      <Container maxWidth="xl" sx={{ minWidth: '100%', px: '0px !important' }}>
        <Box
          sx={{
            minwidth: '100%',
            textAlign: 'center',
            position: 'relative',
            color: grey[50],
            '&::before': {
              display: 'block',
              content: '""',
              width: '100%',
              height: '100%',
              position: 'absolute',
              top: '0',
              left: '0',
              zIndex: '-1',
              backgroundImage: `linear-gradient(to right,rgba(8,11,26,0.9),rgba(8,11,26,0.9)), url(${professionalItCoursesImage})`,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat'
            }
          }}
        >
          <Container sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '460px' }}>
            <Box sx={{ display: 'block' }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h2" sx={{ fontSize: 'calc(1.4rem + 2.6vw)', fontWeight: 600 }}>SalesForce</Typography>
                </Grid>
                <Grid item xs={12} md={6} mx="auto">
                  <Typography paragraph={true} sx={{ mx: 'auto', mb: '0px !important', fontSize: 14 }}>
                    <Box component="span" sx={{ fontWeight: 'bold' }}>Qbitt Technologies</Box> always try to provide the best IT Solutions for Clients to grow up their Business very sharply and smoothly.
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Box>
      </Container>
      <Container maxWidth="lg" sx={{ my: 8 }}>
        <img src={salesForceImage} alt='SalesForce' width="100%"></img>
      </Container>
      <Container maxWidth="lg" sx={{mb: 6}}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8} >
            <Typography variant="h4" fontWeight={600} mb={3} >SalesForce</Typography>
            <Typography paragraph='true' mb={3}>SalesForce provides customer relationship management (CRM) software and applications focused on sales, customer service, marketing automation, e-commerce, analytics, and application development.</Typography>
            <Typography variant="h6" fontWeight={600} mb={3} >Skills You Will Learn</Typography>
            <Grid container spacing={2} mb={3}>
              <Grid item md={6} py={0}>
                <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', py: 0 }}>
                  <ListItem>
                    <ListItemIcon>
                      <CheckIcon sx={{ fontSize: 20, color: '#4caf50', border: 1 }} />
                    </ListItemIcon>
                    <ListItemText primary="Introduction to SalesForce" />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <CheckIcon sx={{ fontSize: 20, color: '#4caf50', border: 1 }} />
                    </ListItemIcon>
                    <ListItemText primary="SalesForce Full Stack Development" />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <CheckIcon sx={{ fontSize: 20, color: '#4caf50', border: 1 }} />
                    </ListItemIcon>
                    <ListItemText primary="Apex Development" />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <CheckIcon sx={{ fontSize: 20, color: '#4caf50', border: 1 }} />
                    </ListItemIcon>
                    <ListItemText primary="Apex Unit Testing" />
                  </ListItem>
                </List>
              </Grid>
              <Grid item md={6} py={0}>
                <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', py: 0 }}>
                  <ListItem>
                    <ListItemIcon>
                      <CheckIcon sx={{ fontSize: 20, color: '#4caf50', border: 1 }} />
                    </ListItemIcon>
                    <ListItemText primary="Apex Programming Fundamentals" />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <CheckIcon sx={{ fontSize: 20, color: '#4caf50', border: 1 }} />
                    </ListItemIcon>
                    <ListItemText primary="Lightening Component Development" />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <CheckIcon sx={{ fontSize: 20, color: '#4caf50', border: 1 }} />
                    </ListItemIcon>
                    <ListItemText primary="Database Manipulation" />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <CheckIcon sx={{ fontSize: 20, color: '#4caf50', border: 1 }} />
                    </ListItemIcon>
                    <ListItemText primary="Exception Handling in Apex" />
                  </ListItem>
                </List>
              </Grid>
            </Grid>
            <Typography variant='h6' fontWeight={600} mb={3}>Who Should Learn</Typography>
            <Typography paragraph='true'>
              <Box sx={{ border: 1, borderColor: grey[500], display: 'inline-block', px: 2, py: 1, mr: 3, mb: 3, bgcolor: grey[300] }}>Programmers</Box>
              <Box sx={{ border: 1, borderColor: grey[500], display: 'inline-block', px: 2, py: 1, mr: 3, mb: 3, bgcolor: grey[300] }}>IT Students</Box>
              {/* <Box sx={{ border: 1, borderColor: grey[500], display: 'inline-block', px: 2, py: 1, mr: 3, mb: 3, bgcolor: grey[300] }}>Business Analyst</Box> */}
            </Typography>



            <Typography variant='h6' fontWeight={600} mb={3}>What You Will Learn</Typography>
            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
              <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                <Typography>SalesForce Fundamentals</Typography>
              </AccordionSummary>
              <AccordionDetails>
              <List>
                  {salesforceFundamentals.map((topic) => (
                    <ListItem disablePadding>
                      <ListItemIcon>
                        <KeyboardArrowRightIcon />
                      </ListItemIcon>
                      <ListItemText primary={topic} />
                    </ListItem>
                  ))}
                </List>
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
              <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                <Typography>Apex Fundamentals</Typography>
              </AccordionSummary>
              <AccordionDetails>
              <List>
                  {apexFundamentals.map((topic) => (
                    <ListItem disablePadding>
                      <ListItemIcon>
                        <KeyboardArrowRightIcon />
                      </ListItemIcon>
                      <ListItemText primary={topic} />
                    </ListItem>
                  ))}
                </List>
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
              <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                <Typography>Control Statements</Typography>
              </AccordionSummary>
              <AccordionDetails>
              <List>
                  {controlStatements.map((topic) => (
                    <ListItem disablePadding>
                      <ListItemIcon>
                        <KeyboardArrowRightIcon />
                      </ListItemIcon>
                      <ListItemText primary={topic} />
                    </ListItem>
                  ))}
                </List>
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
              <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
                <Typography>Classes and Methods</Typography>
              </AccordionSummary>
              <AccordionDetails>
              <List>
                  {classesAndMethods.map((topic) => (
                    <ListItem disablePadding>
                      <ListItemIcon>
                        <KeyboardArrowRightIcon />
                      </ListItemIcon>
                      <ListItemText primary={topic} />
                    </ListItem>
                  ))}
                </List>
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
              <AccordionSummary aria-controls="panel5d-content" id="panel5d-header">
                <Typography>Apex Testing</Typography>
              </AccordionSummary>
              <AccordionDetails>
              <List>
                  {apexTesting.map((topic) => (
                    <ListItem disablePadding>
                      <ListItemIcon>
                        <KeyboardArrowRightIcon />
                      </ListItemIcon>
                      <ListItemText primary={topic} />
                    </ListItem>
                  ))}
                </List>
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
              <AccordionSummary aria-controls="panel6d-content" id="panel6d-header">
                <Typography>SalesForce Object Query Language</Typography>
              </AccordionSummary>
              <AccordionDetails>
              <List>
                  {soql.map((topic) => (
                    <ListItem disablePadding>
                      <ListItemIcon>
                        <KeyboardArrowRightIcon />
                      </ListItemIcon>
                      <ListItemText primary={topic} />
                    </ListItem>
                  ))}
                </List>
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
              <AccordionSummary aria-controls="panel7d-content" id="panel7d-header">
                <Typography>Data Manipulation Language</Typography>
              </AccordionSummary>
              <AccordionDetails>
              <List>
                  {dml.map((topic) => (
                    <ListItem disablePadding>
                      <ListItemIcon>
                        <KeyboardArrowRightIcon />
                      </ListItemIcon>
                      <ListItemText primary={topic} />
                    </ListItem>
                  ))}
                </List>
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
              <AccordionSummary aria-controls="panel8d-content" id="panel8d-header">
                <Typography>Exception Handling</Typography>
              </AccordionSummary>
              <AccordionDetails>
              <List>
                  {exceptionHandling.map((topic) => (
                    <ListItem disablePadding>
                      <ListItemIcon>
                        <KeyboardArrowRightIcon />
                      </ListItemIcon>
                      <ListItemText primary={topic} />
                    </ListItem>
                  ))}
                </List>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12} md={4} >
            <Box p={2} boxShadow={3}>
              <Typography variant='h6'>About Course</Typography>
              <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                <ListItem>
                  <ListItemIcon>
                    <AccessTimeIcon sx={{ fontSize: 34 }} />
                  </ListItemIcon>
                  <ListItemText primary="90 Days" secondary="Online lectures" />
                </ListItem>
                <Divider width={'90%'} sx={{ mx: 'auto' }} />
                <ListItem>
                  <ListItemIcon>
                    <TextSnippetIcon sx={{ fontSize: 34 }} />
                  </ListItemIcon>
                  <ListItemText primary="Completion Certificate" secondary="Awarded on completion" />
                </ListItem>
                <Divider width={'90%'} sx={{ mx: 'auto' }} />
                <ListItem>
                  <ListItemIcon>
                    <OndemandVideoIcon sx={{ fontSize: 34 }} />
                  </ListItemIcon>
                  <ListItemText primary="365 Days Access" secondary="To video lectures" />
                </ListItem>
              </List>
            </Box>
            <CourseList />
          </Grid>
        </Grid>
      </Container>
      <SectionContact />
      <GoToTop />
      <Footer />
      <ScrollButton />
    </>
  );
};

export default SalesForce;