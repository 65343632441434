import * as React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { grey, indigo, orange } from '@mui/material/colors';
import Grid from '@mui/system/Unstable_Grid';
import servicesImage from '../images/our-service.jpg';
// import Link from '@mui/material/Link';

export default function SectionOurServicesTwo() {
    return (
        <Container sx={{ minWidth: '100%', my: 6 }}>

            <Container maxWidht="lg">
                <Box sx={{ py: 3 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Typography variant="h2" sx={{ fontSize: 18 }}>Our Services</Typography>
                            <Box
                                sx={{
                                    width: '120px',
                                    height: '2px',
                                    bgcolor: indigo['A400'],
                                    position: 'relative',
                                    mt: 2,
                                    '&::before': {
                                        display: 'block',
                                        content: '""',
                                        width: '34px',
                                        height: '7px',
                                        background: orange['A400'],
                                        borderRadius: '30px',
                                        position: 'absolute',
                                        top: '-2.5px'
                                    },
                                }}>
                            </Box>
                            <Typography variant="h3" sx={{ mt: 4, fontSize: 36, fontWeight: '600' }}>Provide Best IT Solutions</Typography>
                            <Typography component="span" sx={{
                                fontSize: 24, fontWeight: '300', position: 'relative', '&::after': {
                                    display: 'block',
                                    content: `""`,
                                    width: '100px',
                                    height: '3px',
                                    background: grey[400],
                                    position: 'absolute',
                                    right: '-110px',
                                    top: '14px'
                                }
                            }}>
                                Since 2016
                            </Typography>
                            <Typography paragraph={true} sx={{ mt: 4 }}>
                                <Box component="span" sx={{ fontWeight: 'bold' }}>Qbitt Technologies</Box> is a IT Services Provider with 6 years of industry insight, experience, and partnerships. Since 2016, our clients have trusted us to build powerful applications. From planning and assessment, to enablement, optimization, and ongoing operations management, our IT solutions bring you performance, flexibility, and cost efficiency across the entire Managed Services lifecycle.
                            </Typography>
                            <Typography paragraph={true} sx={{ mt: 4 }}>Whether you need to develop an application or website, we have a full range of services designed to streamline the process. We pride ourselves in delivering fast results complete with go-to-market support.</Typography>
                            {/* <Link href="/Services" underline="hover" sx={{color: 'black', fontWeight: '500', position: 'relative', '&::after':{display: 'block', content: '"\\00BB"', position: 'absolute', top: '-8px', right: '-20px', fontSize: '26px', color: orange[600] }}}>
                                {'More Details'}
                            </Link> */}
                        </Grid>
                        <Grid item xs={12} md={6} sx={{ position: 'relative', px: 5 }}>
                            <img src={servicesImage} alt="About Qbitt Technology" width="100%" />
                        </Grid>
                    </Grid>
                </Box>
                
            </Container>
        </Container>

    );
};