import * as React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { grey, indigo, orange } from '@mui/material/colors';
import Grid from '@mui/system/Unstable_Grid';
import GroupsIcon from '@mui/icons-material/Groups';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import aboutImage from '../images/about-image-01.jpg';
import Link from '@mui/material/Link';

export default function SectionAboutUs() {
    return (
        <Container sx={{ minWidth: '100%', py: 6 }}>

            <Container maxWidht="lg">
                <Box sx={{ py: 3 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Typography variant="h2" sx={{ fontSize: 18 }}>ABOUT US</Typography>
                            <Box
                                sx={{
                                    width: '120px',
                                    height: '2px',
                                    bgcolor: indigo['A400'],
                                    position: 'relative',
                                    mt: 2,
                                    '&::before': {
                                        display: 'block',
                                        content: '""',
                                        width: '34px',
                                        height: '7px',
                                        background: orange['A400'],
                                        borderRadius: '30px',
                                        position: 'absolute',
                                        top: '-2.5px'
                                    },
                                }}>
                            </Box>
                            <Typography variant="h3" sx={{ mt: 4, fontSize: 36, fontWeight: '600' }}>Provide Best IT Solutions</Typography>
                            <Typography component="span" sx={{
                                fontSize: 24, fontWeight: '300', position: 'relative', '&::after': {
                                    display: 'block',
                                    content: `""`,
                                    width: '100px',
                                    height: '3px',
                                    background: grey[400],
                                    position: 'absolute',
                                    right: '-110px',
                                    top: '14px'
                                }
                            }}>
                                Since 2016
                            </Typography>
                            <Typography paragraph={true} sx={{ mt: 4 }}>
                                <Box component="span" sx={{ fontWeight: 'bold' }}>Qbitt Technologies</Box> has a strong relationship with potential customers. This allows businesses to provide a more personal and engaging customer experience. To build a better company, we share our expertise.
                            </Typography>
                            <Typography paragraph={true} sx={{ mt: 4 }}>We have quick and precise assistance tailored to your exact business needs. We provide IT business assistance, including resources, services, tools and support to help you start, run and grow your business globally.</Typography>
                            <Link href="/About" underline="hover" sx={{ color: 'black', fontWeight: '500', position: 'relative', '&:hover::after': { right: '-25px' }, '&::after': { display: 'block', content: '"\\00BB"', position: 'absolute', top: '-8px', right: '-20px', fontSize: '26px', color: orange[600], transition: '.2s ease-in-out' } }}>
                                {'More Details'}
                            </Link>
                        </Grid>
                        <Grid item xs={12} md={6} sx={{ position: 'relative', px: 5 }}>
                            <img src={aboutImage} alt="About Qbitt Technology" width="100%" />
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={{ flexGrow: 1, my: 5 }}>
                    <Grid container spacing={2}>
                        <Grid container xs={12} md={4}>
                            <Grid item xs={3} sx={{ position: 'relative' }}>
                                <Box component="span" sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                                    <GroupsIcon sx={{ fontSize: '60px' }} />
                                </Box>
                            </Grid>
                            <Grid item xs={9}>
                                <Typography variant="h6" sx={{ mb: 1 }}>Creative Team</Typography>
                                <Typography paragraph={true}>
                                    We have creative team, they always ready to help you.
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container xs={12} md={4}>
                            <Grid item xs={3} sx={{ position: 'relative' }}>
                                <Box component="span" sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                                    <WorkspacePremiumIcon sx={{ fontSize: '60px' }} />
                                </Box>
                            </Grid>
                            <Grid item xs={9}>
                                <Typography variant="h6" sx={{ mb: 1 }}>Best Service</Typography>
                                <Typography paragraph={true}>
                                    Best all-in-one customer experience in software.
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container xs={12} md={4}>
                            <Grid item xs={3} sx={{ position: 'relative' }}>
                                <Box component="span" sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                                    <SupportAgentIcon sx={{ fontSize: '60px' }} />
                                </Box>
                            </Grid>
                            <Grid item xs={9}>
                                <Typography variant="h6" sx={{ mb: 1 }}>Online Support</Typography>
                                <Typography paragraph={true}>
                                    Dedicated 24X7 online Technical Support.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </Container>

    );
};