import * as React from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled, useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import Grid from '@mui/system/Unstable_Grid';
import { grey, indigo, orange } from '@mui/material/colors';
import servicesImage from '../images/services-image-01.jpg';
import profileImage from '../images/roshan-sahare.png';
import SectionCounter from '../Components/SectionCounter';
import SectionContact from '../Components/SectionContact';
import Footer from '../Components/Footer';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import ShieldOutlinedIcon from '@mui/icons-material/ShieldOutlined';
import './team-member.css';

import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import SchoolIcon from '@mui/icons-material/School';
import CircleIcon from '@mui/icons-material/Circle';
import GoToTop from '../Components/GoToTop';
import ScrollButton from '../Components/ScrollButton';



const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        backgroundColor: theme.palette.mode === 'light' ? 'rgba(8,11,26,0.9)' : '#000000',
    },
}));

const RoshanSahare = () => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('md'));
    return (
        <>
            <Container maxWidth="xl" sx={{ minWidth: '100%', px: '0px !important' }}>
                <Box
                    sx={{
                        minwidth: '100%',
                        textAlign: 'center',
                        position: 'relative',
                        color: grey[50],
                        '&::before': {
                            display: 'block',
                            content: '""',
                            width: '100%',
                            height: '100%',
                            position: 'absolute',
                            top: '0',
                            left: '0',
                            zIndex: '-1',
                            backgroundImage: `linear-gradient(to right,rgba(8,11,26,0.9),rgba(8,11,26,0.9)), url(${servicesImage})`,
                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat'
                        }
                    }}
                >
                    <Container sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '460px' }}>
                        <Box sx={{ display: 'block' }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="h2" sx={{ fontSize: 'calc(1.4rem + 2.6vw)', fontWeight: 600 }}>Roshan Sahare</Typography>
                                </Grid>
                                <Grid item xs={12} md={6} mx="auto">
                                    <Typography paragraph={true} sx={{ mx: 'auto', mb: '0px !important', fontSize: 14 }}>
                                        Vice President
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </Container>
                </Box>
            </Container>
            <Container maxWidth="lg">
                <Box sx={{ py: 6 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6} sx={{ position: 'relative', px: 5 }} className='team-member'>
                            <img src={profileImage} alt="About Qbitt Technology" width="100%" />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="h3" sx={{ fontSize: 36, fontWeight: '600' }}>Roshan Sahare</Typography>
                            <Typography variant="h4" sx={{ mt: 1, fontSize: 16, color: grey[700], fontWeight: 500 }}>Vice President</Typography>
                            <Box
                                sx={{
                                    width: '120px',
                                    height: '2px',
                                    bgcolor: indigo['A400'],
                                    position: 'relative',
                                    mt: 2,
                                    '&::before': {
                                        display: 'block',
                                        content: '""',
                                        width: '34px',
                                        height: '7px',
                                        background: orange['A400'],
                                        borderRadius: '30px',
                                        position: 'absolute',
                                        top: '-2.5px'
                                    },
                                }}>
                            </Box>
                            <Typography paragraph={true} sx={{ mt: 4 }}>
                                Mr. Roshankumar Sahare plays a critical role in driving the company's success and growth. With his experience focused on innovation and customer satisfaction, he is committed to keeping up with industry trends and developments to provide <Box component="span" sx={{ fontWeight: 'bold' }}>Qbitt Technologies</Box> with a competitive edge.
                            </Typography>
                            <Typography paragraph={true}>
                                Whether he identifies new business opportunities, develops new software products, or builds strong relationships with customers, he is dedicated to driving success for <Box component="span" sx={{ fontWeight: 'bold' }}>Qbitt Technologies</Box> and delivering value to its customers.
                            </Typography>
                            <Typography paragraph={true}><Box component="span" sx={{ fontWeight: 'bold' }}>Email : </Box>roshansahare@qbitt.in</Typography>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
            <Container maxWidth='lg'>
                <Grid container spacing={2} my={3}>
                    <Grid item xs={12} md={6} sx={{ px: 5 }}>
                        <Typography variant='h5' sx={{ fontWeight: 600, mb: 3 }}>My Skills</Typography>
                        <Box sx={{ flexGrow: 1 }}>
                            <Grid container spacing={2} sx={{ px: 0 }}>
                                <Grid item xs={6}>
                                    <Typography sx={{ textAlign: 'left' }}>Database</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography sx={{ textAlign: 'right' }}>95%</Typography>
                                </Grid>
                            </Grid>
                            <BorderLinearProgress variant="determinate" value={95} sx={{ my: 2 }} />
                        </Box>
                        <Box sx={{ flexGrow: 1 }}>
                            <Grid container spacing={2} sx={{ px: 0 }}>
                                <Grid item xs={6}>
                                    <Typography sx={{ textAlign: 'left' }}>Python</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography sx={{ textAlign: 'right' }}>90%</Typography>
                                </Grid>
                            </Grid>
                            <BorderLinearProgress variant="determinate" value={90} sx={{ my: 2 }} />
                        </Box>
                        <Box sx={{ flexGrow: 1 }}>
                            <Grid container spacing={2} sx={{ px: 0 }}>
                                <Grid item xs={6}>
                                    <Typography sx={{ textAlign: 'left' }}>Data Science</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography sx={{ textAlign: 'right' }}>88%</Typography>
                                </Grid>
                            </Grid>
                            <BorderLinearProgress variant="determinate" value={88} sx={{ my: 2 }} />
                        </Box>
                        <Box sx={{ flexGrow: 1 }}>
                            <Grid container spacing={2} sx={{ px: 0 }}>
                                <Grid item xs={6}>
                                    <Typography sx={{ textAlign: 'left' }}>Data Analysis</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography sx={{ textAlign: 'right' }}>93%</Typography>
                                </Grid>
                            </Grid>
                            <BorderLinearProgress variant="determinate" value={93} sx={{ my: 2 }} />
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ px: 5 }}>
                        <Typography variant='h5' sx={{ fontWeight: 600, mb: 3 }}>My Experience</Typography>
                        <Box sx={{ flexGrow: 1 }}>
                            <Grid container spacing={2} sx={{ px: 0 }}>
                                <Grid item xs={2}>
                                    <Box sx={{ width: '100%', height: '100%', position: 'relative', '&::before': { display: 'block', content: '"2012 TO 2017"', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: '1000', fontSize: 11, fontWeight: 700, textAlign: 'center' } }}>
                                        <ShieldOutlinedIcon sx={{ fontSize: 100, color: 'rgba(8,11,26,0.9)', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />
                                    </Box>
                                </Grid>
                                <Grid item xs={10}>
                                    <Typography variant='h6'>Database Administration</Typography>
                                    <Typography paragraph='true' fontWeight={300}>Manages heavy databases with accuracy in the results.</Typography>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box sx={{ flexGrow: 1 }}>
                            <Grid container spacing={2} sx={{ px: 0 }}>
                                <Grid item xs={2}>
                                    <Box sx={{ width: '100%', height: '100%', position: 'relative', '&::before': { display: 'block', content: '"2017 TO 2020"', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: '1000', fontSize: 11, fontWeight: 700, textAlign: 'center' } }}>
                                        <ShieldOutlinedIcon sx={{ fontSize: 100, color: 'rgba(8,11,26,0.9)', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />
                                    </Box>
                                </Grid>
                                <Grid item xs={10}>
                                    <Typography variant='h6'>Data Scientist</Typography>
                                    <Typography paragraph='true' fontWeight={300}>Helps in understanding the customer base and provide accurate results according to the client requirements.</Typography>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box sx={{ flexGrow: 1 }}>
                            <Grid container spacing={2} sx={{ px: 0 }}>
                                <Grid item xs={2}>
                                    <Box sx={{ width: '100%', height: '100%', position: 'relative', '&::before': { display: 'block', content: '"2020 TO 2022"', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: '1000', fontSize: 11, fontWeight: 700, textAlign: 'center' } }}>
                                        <ShieldOutlinedIcon sx={{ fontSize: 100, color: 'rgba(8,11,26,0.9)', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />
                                    </Box>
                                </Grid>
                                <Grid item xs={10}>
                                    <Typography variant='h6'>Project Management</Typography>
                                    <Typography paragraph='true' fontWeight={300}>Number of projects are delivered on time with quality products to the clients.</Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
            <Container maxWidth='lg'>
                <Typography variant='h4' fontWeight={600} textAlign='center' mb={3}>Education</Typography>
                <Typography paragraph='true' mb={5} textAlign='center'>With the interest in the growing Technologies, Mr. Roshan started learning new skills of IT and becomes a wonderful Database person to design and handle huge databases, also started analyzing the data and becomes Data Scientist.</Typography>


                <Timeline position="alternate" align={matches ? 'alternate' : 'left'}>
                    <TimelineItem>
                        <TimelineSeparator>
                            <TimelineDot variant='outlined' sx={{ p: 3, mb: 0, borderColor: 'rgba(8,11,26,0.9)' }}>
                                <SchoolIcon sx={{ fontSize: 30, color: 'rgba(8,11,26,0.9)' }} />
                            </TimelineDot>
                            <TimelineConnector sx={{ height: 120, bgcolor: 'rgba(8,11,26,0.9)' }} />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: '12px', px: 2 }}>
                        </TimelineContent>
                    </TimelineItem>

                    <TimelineItem>
                        <TimelineOppositeContent
                            sx={{ mt: 0.5 }}
                            variant="body2"
                            align="right"
                        >
                            <Typography fontSize={12} fontWeight={600}><Box component="div" sx={{ display: 'inline', borderRadius: 30, border: '2px solid rgba(8,11,26,0.9)', p: 1 }}>July 2007 - April 2011</Box></Typography>
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                            <TimelineDot variant='outlined' sx={{ p: 1.5, m: 0, borderColor: 'rgba(8,11,26,0.9)' }}>
                                <CircleIcon sx={{ fontSize: 10, color: 'rgba(8,11,26,0.9)' }} />
                            </TimelineDot>
                            <TimelineConnector sx={{ height: 160, bgcolor: 'rgba(8,11,26,0.9)' }} />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: '12px', px: 2, mt: -3 }}>
                            <Typography variant="h6" component="span">
                                Bachelors in Engineering
                            </Typography>
                            <Typography>Rashtrasant Tukdoji Maharaj University</Typography>
                        </TimelineContent>
                    </TimelineItem>

                    <TimelineItem>
                        <TimelineOppositeContent
                            sx={{ mt: 0.5 }}
                            align="right"
                            variant="body2"
                        >
                            <Typography fontSize={12} fontWeight={600}><Box component="div" sx={{ display: 'inline', borderRadius: 30, border: '2px solid rgba(8,11,26,0.9)', p: 1 }}>July 2011 - April 2013</Box></Typography>
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                            <TimelineDot variant='outlined' sx={{ p: 1.5, m: 0, borderColor: 'rgba(8,11,26,0.9)' }}>
                                <CircleIcon sx={{ fontSize: 10, color: 'rgba(8,11,26,0.9)' }} />
                            </TimelineDot>
                            <TimelineConnector sx={{ height: 160, bgcolor: 'rgba(8,11,26,0.9)' }} />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: '12px', px: 2, mt: -3 }}>
                            <Typography variant="h6" component="span">
                                Masters in Business Administration
                            </Typography>
                            <Typography>Rashtrasant Tukdoji Maharaj University</Typography>
                        </TimelineContent>
                    </TimelineItem>

                    {/* <TimelineItem>
                        <TimelineOppositeContent
                            variant="body2"
                            sx={{ mt: 0.5 }}
                        >
                            <Typography fontSize={12} fontWeight={600}><Box component="div" sx={{ display: 'inline', borderRadius: 30, border: '2px solid rgba(8,11,26,0.9)', p: 1 }}>July 2010 - April 2014</Box></Typography>
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                            <TimelineDot variant='outlined' sx={{ p: 1.5, m: 0, borderColor: 'rgba(8,11,26,0.9)' }}>
                                <CircleIcon sx={{ fontSize: 10, color: 'rgba(8,11,26,0.9)' }} />
                            </TimelineDot>
                            <TimelineConnector sx={{ height: 160, bgcolor: 'rgba(8,11,26,0.9)' }} />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: '12px', px: 2, mt: -3 }}>
                            <Typography variant="h6" component="span">
                                Bachelors in Engineering
                            </Typography>
                            <Typography>RTM University Nagpur</Typography>
                        </TimelineContent>
                    </TimelineItem> */}
                    <TimelineItem>
                        <TimelineSeparator>
                            <TimelineDot variant='filled' sx={{ p: 1, m: 0, bgcolor: 'rgba(8,11,26,0.9)' }}>
                            </TimelineDot>
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: '12px', px: 2 }}>
                        </TimelineContent>
                    </TimelineItem>
                </Timeline>
            </Container>
            <SectionCounter />
            <SectionContact />
            <GoToTop />
            <Footer />
            <ScrollButton />
        </>
    );
};

export default RoshanSahare;