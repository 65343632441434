import Banner from './Components/SectionBanner';
import AboutUs from './Components/SectionAboutUs';
import Services from './Components/SectionOurServices';
import Courses from './Components/SectionOurCourses';
import Team from './Components/SectionOurTeam';
import Counter from './Components/SectionCounter';
import Contact from './Components/SectionContact';
import Testimonial from './Components/SectionTestimonials';
import Footer from './Components/Footer';
import GoToTop from './Components/GoToTop';
import ScrollButton from './Components/ScrollButton';
import OurClients from './Components/SectionOurClients';


const Home = () => {
    return (
        <>
        <Banner/>
        <AboutUs/>
        <Services/>
        <Testimonial/>
        <Team/>
        <Courses/>
        <OurClients />
        <Counter/>
        <Contact/>
        <GoToTop />
        <Footer/>
        <ScrollButton />
        </>
    );
  };
  
  export default Home;