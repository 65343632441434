import * as React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { grey, orange } from '@mui/material/colors';
import Grid from '@mui/system/Unstable_Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import logo from '../logo-2.png';
import { Link } from '@mui/material';




export default function Footer() {
    return (
        <Container maxWidth="xl" sx={{ bgcolor: grey[50], width: '100%', pl: "0px !important", pr: '0px !important', pt: 6, overflow: 'hidden' }}>
            <Container maxWidth="lg" sx={{ mb: 5 }}>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <img src={logo} width="180px" alt="Qbitt Technologies Logo" />
                            <Typography paragraph={true} mt='20px'>
                            <Box component="span" sx={{ fontWeight: 'bold' }}>Qbitt Technologies</Box> has a strong relationship with potential customers. This allows businesses to provide a more personal and engaging customer experience.
                            </Typography>
                            <Typography paragraph={true}>
                                &copy; {(new Date().getFullYear())} | <Typography component="span" fontWeight={600}>Qbitt Technologies</Typography>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Typography variant="h5" sx={{ fontWeight: 600, fontSize: 22 }}>Services</Typography>
                            <List sx={{ position: 'relative' }}>
                                <ListItem disablePadding sx={{ '&:hover:before':{left: 8},'&:before': { display: 'block', content: '"\\00BB"', position: 'absolute', top: '-1px', fontSize: '26px', color: orange[600], transition: '.2s ease-in-out !important' } }}>
                                    <Link href="/Services/Website-Development" underline="none" color="inherit">
                                        <ListItemText primary="Website Development" sx={{ marginLeft: 3 }} />
                                    </Link>
                                </ListItem>
                                <ListItem disablePadding sx={{ '&:hover:before':{left: 8},'&:before': { display: 'block', content: '"\\00BB"', position: 'absolute', top: '-1px', fontSize: '26px', color: orange[600], transition: '.3s ease-in-out' } }}>
                                    <Link href="/Services/Search-Engine-Optimization" underline="none" color="inherit">
                                        <ListItemText primary="Search Engine Optimization" sx={{ marginLeft: 3 }} />
                                    </Link>
                                </ListItem>
                                <ListItem disablePadding sx={{ '&:hover:before':{left: 8},'&:before': { display: 'block', content: '"\\00BB"', position: 'absolute', top: '-1px', fontSize: '26px', color: orange[600], transition: '.3s ease-in-out' } }}>
                                    <Link href="/Services/Digital-Marketing" underline="none" color="inherit">
                                        <ListItemText primary="Digital Marketing" sx={{ marginLeft: 3 }} />
                                    </Link>
                                </ListItem>
                                <ListItem disablePadding sx={{ '&:hover:before':{left: 8},'&:before': { display: 'block', content: '"\\00BB"', position: 'absolute', top: '-1px', fontSize: '26px', color: orange[600], transition: '.3s ease-in-out' } }}>
                                    <Link href="/Services/Software-Development" underline="none" color="inherit">
                                        <ListItemText primary="Software Development" sx={{ marginLeft: 3 }} />
                                    </Link>
                                </ListItem>
                                <ListItem disablePadding sx={{ '&:hover:before':{left: 8},'&:before': { display: 'block', content: '"\\00BB"', position: 'absolute', top: '-1px', fontSize: '26px', color: orange[600], transition: '.3s ease-in-out' } }}>
                                    <Link href="/Services/Android-App-Development" underline="none" color="inherit">
                                        <ListItemText primary="Android App Development" sx={{ marginLeft: 3 }} />
                                    </Link>
                                </ListItem>
                                <ListItem disablePadding sx={{ '&:hover:before':{left: 8},'&:before': { display: 'block', content: '"\\00BB"', position: 'absolute', top: '-1px', fontSize: '26px', color: orange[600], transition: '.3s ease-in-out' } }}>
                                    <Link href="/Services/Professional-IT-Courses" underline="none" color="inherit">
                                        <ListItemText primary="Professional IT Courses" sx={{ marginLeft: 3 }} />
                                    </Link>
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Typography variant="h5" sx={{ fontWeight: 600, fontSize: 22 }}>Contact Us</Typography>
                            <List sx={{ position: 'relative' }}>
                                <ListItem disablePadding sx={{ '&:before': { display: 'block', content: '"\\00BB"', position: 'absolute', top: '-1px', fontSize: '26px', color: orange[600] } }}>
                                    <ListItemText primary="Plot No 190, NIT Layout," sx={{ marginLeft: '20px' }} />
                                </ListItem>
                                <ListItem disablePadding >
                                    <ListItemText primary="Nalanda Nagar, Nari Road," sx={{ marginLeft: '20px' }} />
                                </ListItem>
                                <ListItem disablePadding>
                                    <ListItemText primary="Nagpur 440026, India" sx={{ marginLeft: '20px' }} />
                                </ListItem>
                                <ListItem disablePadding sx={{ '&:before': { display: 'block', content: '"\\00BB"', position: 'absolute', top: '-1px', fontSize: '26px', color: orange[600] } }}>
                                    <ListItemText primary="admin@qbitt.in" sx={{ marginLeft: '20px' }} />
                                </ListItem>
                                <ListItem disablePadding sx={{ '&:before': { display: 'block', content: '"\\00BB"', position: 'absolute', top: '-1px', fontSize: '26px', color: orange[600] } }}>
                                    <ListItemText primary="(+91) 7020171320" sx={{ marginLeft: '20px' }} />
                                </ListItem>
                            </List>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </Container>
    );
};