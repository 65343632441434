import * as React from "react";
import Slider from "react-slick";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
// import aboutImage from '../images/about-image-01.jpg';
import { Link } from '@mui/material';
import './TeamSlider.css';
import rahulGajbhiyeImage from '../images/rahul-gajbhiye.jpg';
import shruteshBhowateImage from '../images/shrutesh-bhowate.jpg';
import vinayKumarImage from '../images/vinay-kumar.png';
import roshanSahareImage from '../images/roshan-sahare.png';




export default function TestimonialSlider() {
    const settings = {
        dots: true,
        arrows: false,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 3,
        speed: 500,
        responsive: [
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <div>
            <Slider {...settings} className="team-slider">
                <div>
                    <Link href="Team/Rahul-Gajbhiye" underline="none" color="inherit">
                        <Box sx={{ widht: '100%', bgcolor: '#fff', m: 2, textAlign: 'center' }}>
                            <Box sx={{ width: '100%' }}>
                                <img src={rahulGajbhiyeImage} alt="Rahul Gajbhiye" width="100%" />
                            </Box>
                            <Typography varient="h4" sx={{ fontSize: 18, fontWeight: 600, pt: 2 }}>Rahul Gajbhiye</Typography>
                            <Typography varient="h6" pb={2} fontSize={14}>CEO</Typography>
                        </Box>
                    </Link>
                </div>
                <div>
                    <Link href="Team/Vinay-Kumar" underline="none" color="inherit">
                        <Box sx={{ widht: '100%', bgcolor: '#fff', m: 2, textAlign: 'center' }}>
                            <Box sx={{ width: '100%' }}>
                                <img src={vinayKumarImage} alt="Vinay Kumar" width="100%" />
                            </Box>
                            <Typography varient="h4" sx={{ fontSize: 18, fontWeight: 600, pt: 2 }}>Vinay Kumar</Typography>
                            <Typography varient="h6" pb={2} fontSize={14}>Managing Director</Typography>
                        </Box>
                    </Link>
                </div>
                <div>
                    <Link href="Team/Roshan-Sahare" underline="none" color="inherit">
                        <Box sx={{ widht: '100%', bgcolor: '#fff', m: 2, textAlign: 'center' }}>
                            <Box sx={{ width: '100%' }}>
                                <img src={roshanSahareImage} alt="Roshan Sahare" width="100%" />
                            </Box>
                            <Typography varient="h4" sx={{ fontSize: 18, fontWeight: 600, pt: 2 }}>Roshan Sahare</Typography>
                            <Typography varient="h6" pb={2} fontSize={14}>Vice President</Typography>
                        </Box>
                    </Link>
                </div>
                <div>
                    <Link href="Team/Shrutesh-Bhowate" underline="none" color="inherit">
                        <Box sx={{ widht: '100%', bgcolor: '#fff', m: 2, textAlign: 'center' }}>
                            <Box sx={{ width: '100%' }}>
                                <img src={shruteshBhowateImage} alt="Shrutesh Bhowate" width="100%" />
                            </Box>
                            <Typography varient="h4" sx={{ fontSize: 18, fontWeight: 600, pt: 2 }}>Shrutesh Bhowate</Typography>
                            <Typography varient="h6" pb={2} fontSize={14}>Cheif Marketing Officer</Typography>
                        </Box>
                    </Link>
                </div>
                {/* <div>
                    <Link href="Team/Dhaneshwar-Mondal" underline="none" color="inherit">
                        <Box sx={{ widht: '100%', bgcolor: '#fff', m: 2, textAlign: 'center' }}>
                            <Box sx={{ width: '100%' }}>
                                <img src={aboutImage} alt="Dhaneshwar Mondal" width="100%" />
                            </Box>
                            <Typography varient="h4" sx={{ fontSize: 18, fontWeight: 600, pt: 2 }}>Dhaneshwar Mondal</Typography>
                            <Typography varient="h6" pb={2} fontSize={14}>Cheif Technology Officer</Typography>
                        </Box>
                    </Link>
                </div>
                <div>
                    <Link href="Team/Hema" underline="none" color="inherit">
                        <Box sx={{ widht: '100%', bgcolor: '#fff', m: 2, textAlign: 'center' }}>
                            <Box sx={{ width: '100%' }}>
                                <img src={aboutImage} alt="Hema" width="100%" />
                            </Box>
                            <Typography varient="h4" sx={{ fontSize: 18, fontWeight: 600, pt: 2 }}>Hema</Typography>
                            <Typography varient="h6" pb={2} fontSize={14}>HR & Admin</Typography>
                        </Box>
                    </Link>
                </div>
                <div>
                    <Link href="Team/Sandeep-Tijare" underline="none" color="inherit">
                        <Box sx={{ widht: '100%', bgcolor: '#fff', m: 2, textAlign: 'center' }}>
                            <Box sx={{ width: '100%' }}>
                                <img src={aboutImage} alt="Sandeep Tijare" width="100%" />
                            </Box>
                            <Typography varient="h4" sx={{ fontSize: 18, fontWeight: 600, pt: 2 }}>Sandeep Tijare</Typography>
                            <Typography varient="h6" pb={2} fontSize={14}>Project Manager</Typography>
                        </Box>
                    </Link>
                </div>
                <div>
                    <Link href="Team/Trivesh-Wasnik" underline="none" color="inherit">
                        <Box sx={{ widht: '100%', bgcolor: '#fff', m: 2, textAlign: 'center' }}>
                            <Box sx={{ width: '100%' }}>
                                <img src={aboutImage} alt="Trivesh Wasnik" width="100%" />
                            </Box>
                            <Typography varient="h4" sx={{ fontSize: 18, fontWeight: 600, pt: 2 }}>Trivesh Wasnik</Typography>
                            <Typography varient="h6" pb={2} fontSize={14}>Full Stack Developer</Typography>
                        </Box>
                    </Link>
                </div> */}
            </Slider>
        </div>
    );
}