import * as React from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled, useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import Grid from '@mui/system/Unstable_Grid';
import { grey, indigo, orange } from '@mui/material/colors';
import servicesImage from '../images/services-image-01.jpg';
import profileImage from '../images/vinay-kumar.png';
import SectionCounter from '../Components/SectionCounter';
import SectionContact from '../Components/SectionContact';
import Footer from '../Components/Footer';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import ShieldOutlinedIcon from '@mui/icons-material/ShieldOutlined';
import './team-member.css';

import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import SchoolIcon from '@mui/icons-material/School';
import CircleIcon from '@mui/icons-material/Circle';
import GoToTop from '../Components/GoToTop';
import ScrollButton from '../Components/ScrollButton';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        backgroundColor: theme.palette.mode === 'light' ? 'rgba(8,11,26,0.9)' : '#000000',
    },
}));

const VinayKumar = () => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('md'));
    return (
        <>
            <Container maxWidth="xl" sx={{ minWidth: '100%', px: '0px !important' }}>
                <Box
                    sx={{
                        minwidth: '100%',
                        textAlign: 'center',
                        position: 'relative',
                        color: grey[50],
                        '&::before': {
                            display: 'block',
                            content: '""',
                            width: '100%',
                            height: '100%',
                            position: 'absolute',
                            top: '0',
                            left: '0',
                            zIndex: '-1',
                            backgroundImage: `linear-gradient(to right,rgba(8,11,26,0.9),rgba(8,11,26,0.9)), url(${servicesImage})`,
                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat'
                        }
                    }}
                >
                    <Container sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '460px' }}>
                        <Box sx={{ display: 'block' }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="h2" sx={{ fontSize: 'calc(1.4rem + 2.6vw)', fontWeight: 600 }}>Vinay Kumar</Typography>
                                </Grid>
                                <Grid item xs={12} md={6} mx="auto">
                                    <Typography paragraph={true} sx={{ mx: 'auto', mb: '0px !important', fontSize: 14 }}>
                                        Managing Director
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </Container>
                </Box>
            </Container>
            <Container maxWidth="lg">
                <Box sx={{ py: 6 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6} sx={{ position: 'relative', px: 5 }} className='team-member'>
                            <img src={profileImage} alt="About Qbitt Technology" width="100%" />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="h3" sx={{ fontSize: 36, fontWeight: '600' }}>Vinay Kumar</Typography>
                            <Typography variant="h4" sx={{ mt: 1, fontSize: 16, color: grey[700], fontWeight: 500 }}>Managing Director</Typography>
                            <Box
                                sx={{
                                    width: '120px',
                                    height: '2px',
                                    bgcolor: indigo['A400'],
                                    position: 'relative',
                                    mt: 2,
                                    '&::before': {
                                        display: 'block',
                                        content: '""',
                                        width: '34px',
                                        height: '7px',
                                        background: orange['A400'],
                                        borderRadius: '30px',
                                        position: 'absolute',
                                        top: '-2.5px'
                                    },
                                }}>
                            </Box>
                            <Typography paragraph={true} sx={{ mt: 4 }}>
                                Mr. Vinay Kumar manages the <Box component="span" sx={{ fontWeight: 'bold' }}>Qbitt Technologies</Box> software development team, recruiting, training, and managing them to ensure they have the skills and resources they need to develop innovative and high-quality software solutions.
                            </Typography>
                            <Typography paragraph={true}>
                                He focus on innovation, customer satisfaction, and cutting-edge technology, he is always looking for ways to improve <Box component="span" sx={{ fontWeight: 'bold' }}>Qbitt Technologies</Box> software development solutions and enhance its reputation in the marketplace.
                            </Typography>
                            <Typography paragraph={true}><Box component="span" sx={{ fontWeight: 'bold' }}>Email : </Box>vinaykumar@qbitt.in</Typography>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
            <Container maxWidth='lg'>
                <Grid container spacing={2} my={3}>
                    <Grid item xs={12} md={6} sx={{ px: 5 }}>
                        <Typography variant='h5' sx={{ fontWeight: 600, mb: 3 }}>My Skills</Typography>
                        <Box sx={{ flexGrow: 1 }}>
                            <Grid container spacing={2} sx={{ px: 0 }}>
                                <Grid item xs={6}>
                                    <Typography sx={{ textAlign: 'left' }}>Java</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography sx={{ textAlign: 'right' }}>90%</Typography>
                                </Grid>
                            </Grid>
                            <BorderLinearProgress variant="determinate" value={90} sx={{ my: 2 }} />
                        </Box>
                        <Box sx={{ flexGrow: 1 }}>
                            <Grid container spacing={2} sx={{ px: 0 }}>
                                <Grid item xs={6}>
                                    <Typography sx={{ textAlign: 'left' }}>Python</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography sx={{ textAlign: 'right' }}>90%</Typography>
                                </Grid>
                            </Grid>
                            <BorderLinearProgress variant="determinate" value={90} sx={{ my: 2 }} />
                        </Box>
                        <Box sx={{ flexGrow: 1 }}>
                            <Grid container spacing={2} sx={{ px: 0 }}>
                                <Grid item xs={6}>
                                    <Typography sx={{ textAlign: 'left' }}>SQL</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography sx={{ textAlign: 'right' }}>80%</Typography>
                                </Grid>
                            </Grid>
                            <BorderLinearProgress variant="determinate" value={80} sx={{ my: 2 }} />
                        </Box>
                        <Box sx={{ flexGrow: 1 }}>
                            <Grid container spacing={2} sx={{ px: 0 }}>
                                <Grid item xs={6}>
                                    <Typography sx={{ textAlign: 'left' }}>Selenium</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography sx={{ textAlign: 'right' }}>95%</Typography>
                                </Grid>
                            </Grid>
                            <BorderLinearProgress variant="determinate" value={95} sx={{ my: 2 }} />
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ px: 5 }}>
                        <Typography variant='h5' sx={{ fontWeight: 600, mb: 3 }}>My Experience</Typography>
                        <Box sx={{ flexGrow: 1 }}>
                            <Grid container spacing={2} sx={{ px: 0 }}>
                                <Grid item xs={2}>
                                    <Box sx={{ width: '100%', height: '100%', position: 'relative', '&::before': { display: 'block', content: '"2013 TO 2016"', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: '1000', fontSize: 11, fontWeight: 700, textAlign: 'center' } }}>
                                        <ShieldOutlinedIcon sx={{ fontSize: 100, color: 'rgba(8,11,26,0.9)', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />
                                    </Box>
                                </Grid>
                                <Grid item xs={10}>
                                    <Typography variant='h6'>Software Developer</Typography>
                                    <Typography paragraph='true' fontWeight={300}>Contribute in application development for various clients with logical solutions.</Typography>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box sx={{ flexGrow: 1 }}>
                            <Grid container spacing={2} sx={{ px: 0 }}>
                                <Grid item xs={2}>
                                    <Box sx={{ width: '100%', height: '100%', position: 'relative', '&::before': { display: 'block', content: '"2016 TO 2020"', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: '1000', fontSize: 11, fontWeight: 700, textAlign: 'center' } }}>
                                        <ShieldOutlinedIcon sx={{ fontSize: 100, color: 'rgba(8,11,26,0.9)', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />
                                    </Box>
                                </Grid>
                                <Grid item xs={10}>
                                    <Typography variant='h6'>Automation Tester</Typography>
                                    <Typography paragraph='true' fontWeight={300}>Delivers high quality products to customers by doing Automation Testing.</Typography>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box sx={{ flexGrow: 1 }}>
                            <Grid container spacing={2} sx={{ px: 0 }}>
                                <Grid item xs={2}>
                                    <Box sx={{ width: '100%', height: '100%', position: 'relative', '&::before': { display: 'block', content: '"2020 TO 2022"', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: '1000', fontSize: 11, fontWeight: 700, textAlign: 'center' } }}>
                                        <ShieldOutlinedIcon sx={{ fontSize: 100, color: 'rgba(8,11,26,0.9)', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />
                                    </Box>
                                </Grid>
                                <Grid item xs={10}>
                                    <Typography variant='h6'>Project Manager</Typography>
                                    <Typography paragraph='true' fontWeight={300}>Leads the project and fulfill all the client requirements for a project within time limit.</Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
            <Container maxWidth='lg'>
                <Typography variant='h4' fontWeight={600} textAlign='center' mb={3}>Education</Typography>
                <Typography paragraph='true' mb={5} textAlign='center'>With the good logical ability, Mr. Vinay Kumar learned various programming languages which helps him in his academics to score. He always upgrades his skills to meet the industry standards.</Typography>
                <Timeline position="alternate" align={matches ? 'alternate' : 'left'}>
                    <TimelineItem>
                        <TimelineSeparator>
                            <TimelineDot variant='outlined' sx={{ p: 3, mb: 0, borderColor: 'rgba(8,11,26,0.9)' }}>
                                <SchoolIcon sx={{ fontSize: 30, color: 'rgba(8,11,26,0.9)' }} />
                            </TimelineDot>
                            <TimelineConnector sx={{ height: 120, bgcolor: 'rgba(8,11,26,0.9)' }} />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: '12px', px: 2 }}>
                        </TimelineContent>
                    </TimelineItem>

                    <TimelineItem>
                        <TimelineOppositeContent
                            sx={{ mt: 0.5 }}
                            variant="body2"
                            align="right"
                        >
                            <Typography fontSize={12} fontWeight={600}><Box component="div" sx={{ display: 'inline', borderRadius: 30, border: '2px solid rgba(8,11,26,0.9)', p: 1 }}>July 2009 - April 2013</Box></Typography>
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                            <TimelineDot variant='outlined' sx={{ p: 1.5, m: 0, borderColor: 'rgba(8,11,26,0.9)' }}>
                                <CircleIcon sx={{ fontSize: 10, color: 'rgba(8,11,26,0.9)' }} />
                            </TimelineDot>
                            <TimelineConnector sx={{ height: 160, bgcolor: 'rgba(8,11,26,0.9)' }} />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: '12px', px: 2, mt: -3 }}>
                            <Typography variant="h6" component="span">
                                Bachelors in Engineering
                            </Typography>
                            <Typography>Rastrasant Tukdoji Maharaj University</Typography>
                        </TimelineContent>
                    </TimelineItem>

                    <TimelineItem>
                        <TimelineOppositeContent
                            sx={{ mt: 0.5 }}
                            align="right"
                            variant="body2"
                        >
                            <Typography fontSize={12} fontWeight={600}><Box component="div" sx={{ display: 'inline', borderRadius: 30, border: '2px solid rgba(8,11,26,0.9)', p: 1 }}>July 2013 - April 2015</Box></Typography>
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                            <TimelineDot variant='outlined' sx={{ p: 1.5, m: 0, borderColor: 'rgba(8,11,26,0.9)' }}>
                                <CircleIcon sx={{ fontSize: 10, color: 'rgba(8,11,26,0.9)' }} />
                            </TimelineDot>
                            <TimelineConnector sx={{ height: 160, bgcolor: 'rgba(8,11,26,0.9)' }} />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: '12px', px: 2, mt: -3 }}>
                            <Typography variant="h6" component="span">
                                Masters in Technology
                            </Typography>
                            <Typography>BATU University</Typography>
                        </TimelineContent>
                    </TimelineItem>
                    <TimelineItem>
                        <TimelineSeparator>
                            <TimelineDot variant='filled' sx={{ p: 1, m: 0, bgcolor: 'rgba(8,11,26,0.9)' }}>
                            </TimelineDot>
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: '12px', px: 2 }}>
                        </TimelineContent>
                    </TimelineItem>
                </Timeline>
            </Container>
            <SectionCounter />
            <SectionContact />
            <GoToTop />
            <Footer />
            <ScrollButton />
        </>
    );
};

export default VinayKumar;